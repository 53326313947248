<template>
    <div class="box">
        <el-checkbox-group v-model="checkedDevices" style="margin:0 auto;">
            <el-checkbox v-for="(item,index) in deviceData" :label="item.id" :key="index" style="display: inline-flex;align-items: center;margin-bottom:10px;">
                <el-descriptions class="margin-top" :column="24" size="medium" border>
                    <el-descriptions-item :span="12">
                        <template slot="label">
                            <i class="el-icon-user"></i>
                            设备号
                        </template>
                        {{item.serial_number}}
                    </el-descriptions-item>
                    <el-descriptions-item :span="12">
                    <template slot="label">
                        <i class="el-icon-mobile-phone"></i>
                        充电宝总数
                    </template>
                    {{item.battery_total}}
                    </el-descriptions-item>
                </el-descriptions>
            </el-checkbox>
        </el-checkbox-group>
        <div style="width:100%;display:flex;justify-content: flex-end;box-sizing: border-box;padding: 10px;">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="params.page"
            :hide-on-single-page="false"
            :small="true"
            :page-size="params.page_count"
            layout="total, prev, pager, next"
            :total="total">
          </el-pagination>
        </div>
        <div style="width:50%;margin:20px auto;display: flex;justify-content: space-around;">
            <el-button type="primary" plain>取消</el-button>
            <el-button type="primary" @click="submit">确认</el-button>
        </div>
    </div>
  </template>
  
  <script>
    let _this;
    import {getUnboundList,shareDevice} from "@/api/agent.js";
    export default {
        props:['id'],
        data(){
            return{
                params:{
                    page:1,
                    page_count:10
                },
                total:0,
                deviceData:[],
                checkedDevices:[]
            }
        },
        mounted(){
            _this = this
            _this.init()
        },
        methods:{
            // 初始化数据
            init() {
                getUnboundList(this.params).then(res=>{
                    if (res.code === 200){
                        this.deviceData = res.data.data
                        this.total = res.data.total
                        console.log(this.deviceData)
                    }
                })
            },
            
            handleCurrentChange(val) {
                this.params.page = val;
                this.init();
            },
            submit(){
                console.log(this.id)
                if(this.checkedDevices.length === 0){
                    this.$message({
                        message: '请选择设备!',
                        type: 'warning'
                    });
                    return false
                }
                shareDevice(this.id,{'device_ids':JSON.stringify(this.checkedDevices)}).then(res=>{
                    if(res.code === 200){
                        this.$message({
                            message: '分配成功',
                            type: 'success'
                        });
                        setTimeout(() => {
                            console.log(2)
                            _this.$emit("success");
                        }, 1000);
                    }
                })
            }
        }
    };
  </script>
  <style lang="scss" scoped>
  </style>
  