<template>
  <section style="padding:0 5%;">
    <el-form ref="form" label-position="top" status-icon :model="form" :rules="rules" label-width="100px"
             style="width:100%;overflow:auto">
      <el-divider content-position="left">白名单信息</el-divider>
      <el-form-item label="联系方式" prop="phone" required>
        <el-input placeholder="请输入联系方式" v-model="form.phone">
        </el-input>
      </el-form-item>


      <el-form-item label="类型" prop="type">
        <el-select v-model="form.type" placeholder="请选择投放点" filterable clearable style="margin-top:10px;">
        <!-- <el-option label="请选择需搜索投放点">请选择需搜索投放点</el-option> -->
        <el-option
          v-for="item in whiteType"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
      </el-form-item>
      <el-form-item label="投放点" prop="store" v-if="form.type===2 || form.type === 3">
        <el-select v-model="form.store_id" placeholder="请选择投放点" filterable clearable style="margin-top:10px;">
        <!-- <el-option label="请选择需搜索投放点">请选择需搜索投放点</el-option> -->
        <el-option
          v-for="item in storeList"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
      </el-form-item>
      <el-form-item label="备注" prop="comment">
        <el-input placeholder="请填写备注" type="textarea" v-model="form.comment">
        </el-input>
      </el-form-item>

      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="addAssistant('form')" :plain='true'>立即添加</el-button>
      </el-form-item>

    </el-form>
  </section>
</template>

<script>
  let _this;
  import {addData} from '@/api/whiteList.js';
  import { getListSelect} from "@/api/store.js";

  export default {
    data() {
      const validatePhone = (rule, value, callback) => {
        const regExp = /^(0|86|17951)?(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])[0-9]{8}$/
        if (!regExp.test(value)) {
          callback(new Error('请输入正确的手机号'))
        } else {
          callback()
        }
      }
      return {
        form: {
          phone: '',
          comment:'',
          store_id:'',
          type:0
        },
        whiteType:[
          {
            id:0,
            name:"特殊人群"
          },
          {
            id:1,
            name:"市场推广"
          },
          {
            id:2,
            name:"店主"
          },
          {
            id:3,
            name:"店员"
          },
        ],
        storeList:[],
        rules: {
          phone: [
            {required: true, message: "请输入联系方式", trigger: "blur"},
            {
              validator: validatePhone,
              trigger: 'change',
            }
          ],
        },
      };
    },
    computed: {},

    mounted() {
      _this = this;
      _this.init();
    },

    methods: {
      init(){
        getListSelect().then(res=>{
          if(res.code === 200){
            this.storeList = res.data
          }
        })
      },
      // 数据初始化
      close() {
        _this.$emit('close');
      },


      //  添加
      addAssistant(form) {

        _this.$refs["form"].validate(valid => {
          if (valid) {
            if(this.form.store_id===''){
              this.form.store_id=0
            }
            addData(_this.form)
              .then((res) => {
                if (res.code === 200) {
                  _this.$message({
                    message: "添加成功",
                    type: "success",
                  });
                  setTimeout(() => {
                    _this.$emit("success");
                  }, 1000);
                }
              })
              .catch(error => {
              });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
    }
  };
</script>
<style lang="scss" scoped>
  .el-drawer {
    overflow: auto !important;
  }

  .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow: hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
