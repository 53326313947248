<template>
  <section style="padding:0 5%;">
    <el-form
      ref="form"
      label-position="top"
      status-icon
      :model="form"
      :rules="rules"
      label-width="100px"
      style="width:100%;"
    >
      <el-form-item label="内容名称" prop="name" required>
        <el-input v-model="form.name" placeholder="内容名称,不可重复"></el-input>
      </el-form-item>

      <el-form-item label="类型" prop="type" required>
        <el-radio-group v-model="form.type" size="small">
          <el-radio :label="0" border @change="typeChange($event)">海报</el-radio>
          <el-radio :label="1" border @change="typeChange($event)">视频</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="上传视频封面" prop="poster" v-if="form.type===1">
        <upload-qiniu @success="handleContentSuccess3" path="media" @remove="handleContentRemove3" :multiple="false" :num="1" :images="form.poster"></upload-qiniu>
      </el-form-item>
      <el-form-item label="上传内容" prop="src">
        <upload-qiniu @success="handleContentSuccess" path="media" @remove="handleContentRemove" :multiple="false" :num="1" :images="form.src"></upload-qiniu>
      </el-form-item>
      <el-form-item label="上传小设备视频封面" prop="min_poster" v-if="form.type===1">
        <upload-qiniu @success="handleContentSuccess4" path="media" @remove="handleContentRemove4" :multiple="false" :num="1" :images="form.min_poster"></upload-qiniu>
      </el-form-item>
      <el-form-item label="上传小设备内容" prop="min_src">
        <upload-qiniu @success="handleContentSuccess2" path="media" @remove="handleContentRemove2" :multiple="false" :num="1" :images="form.min_src"></upload-qiniu>
      </el-form-item>

      <el-form-item label="显示区域" prop="province_id" v-if="groupType!=1">
        <districts-cascader :level="3" :showAll="true" @choose="handleAreaDistrictsChoose"></districts-cascader>
      </el-form-item>

      <el-form-item label="显示日期范围" prop="start_Date">
        <div style="display:inline-flex;width: 100%;">
          <el-date-picker
            v-model="form.start_date"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择开始日期">
          </el-date-picker>
          <span style="margin-left:5px;margin-right:5px;">至</span>
          <el-date-picker
            v-model="form.end_date"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择结束日期">
          </el-date-picker>
        </div>
      </el-form-item>
      <el-form-item label="显示时间范围" prop="start_time" required>
        <div style="display:inline-flex;width: 100%;">
          <el-time-select
            placeholder="起始时间"
            v-model="form.start_time"
            :picker-options="{
              start: '00:00',
              step: '00:30',
              end: '23:30'
            }">
          </el-time-select>
          <span style="margin-left:5px;margin-right:5px;">至</span>
          <el-time-select
            placeholder="结束时间"
            v-model="form.end_time"
            :picker-options="{
              start: '00:00',
              step: '00:30',
              end: '23:59',
              minTime: form.start_time
            }">
          </el-time-select>
        </div>
      </el-form-item>

      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="submit('form')" :plain="true">立即添加</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
  let _this;
  import {addData} from "@/api/media.js";
  import {getListSelect} from "@/api/store.js"
  import UploadQiniu from "@/components/tool/lib/upload/index.vue";
  import DistrictsCascader from "@/components/tool/lib/districts-cascader/Index.vue";
  let form = {
    name: "",
    type: 0,
    src: "",
    min_src:'',
    poster:'',
    min_poster:'',
    province_id:0,
    city_id:0,
    area_id:0,
    start_time:"",
    end_time:"",
    start_date:'',
    end_date:'',
  }
  export default {
    components: {DistrictsCascader, UploadQiniu},
    data() {
      return {
        form: form,
        groupType:0,
        rules: {
          name: [
            {required: true, message: "SN不能为空,不可重复", trigger: "blur"},
          ],
          type: [
            {required: true, message: "类型不能为空", trigger: "blur"},
          ],
          start_time: [
            {required: true, message: "请选择显示时间范围", trigger: "change"},
          ],
        },

        imageUrl: [],
        media_time:[new Date(), new Date(2016, 9, 10, 9, 40)],

        service_data:{
          service_ids:[],
          service_ids_name:[],
          service_province:'',
          service_city:'',
          service_area:'',
          service_province_str:'',
          service_city_str:'',
          service_area_str:''
        },

      };
    },

    computed: {},

    mounted() {
      _this = this;
      _this.init();
      this.groupType = this.$store.state.user.userData.group_type
    },

    methods: {
      // 初始化数据
      init() {
        getListSelect().then(res=>{
          if(res.code === 200){
            this.stores = res.data
          }
        })
      },
      typeChange(e){
        console.log(e)
        this.form.src=''
        this.form.min_poster=''
        this.form.min_src=''
        this.form.poster=''
      },
     
      close() {
        _this.$emit("close");
      },
      handleContentSuccess(e) {
        console.log(e)
        this.form.src = e[0];
      },
      handleContentRemove(e) {
        this.form.src = '';
      },
      handleContentSuccess2(e) {
        console.log(e)
        this.form.min_src = e[0];
      },
      handleContentRemove2(e) {
        this.form.min_src = '';
      },
      handleContentSuccess3(e) {
        console.log(e)
        this.form.poster = e[0];
      },
      handleContentRemove3(e) {
        this.form.poster = '';
      },
      handleContentSuccess4(e) {
        console.log(e)
        this.form.min_poster = e[0];
      },
      handleContentRemove4(e) {
        this.form.min_poster = '';
      },

      handleAreaDistrictsChoose(e){
        this.service_data.service_province = e.province_code
        this.service_data.service_province_str = e.province
        this.service_data.service_city = e.city_code
        this.service_data.service_city_str = e.city
        this.service_data.service_area = e.area_code
        this.service_data.service_area_str = e.area
        this.form.province_id = e.province_code
        this.form.city_id = e.city_code
        this.form.area_id = e.area_code
      },
      submit(form) {
        console.log(_this.form)
        this.$refs[form].validate(valid => {
          if (valid) {
            if(_this.form.type ===1){
              if(this.form.src!=''){
                if(this.form.poster == ''){
                  _this.$message({
                    message: "请上传视频封面",
                    type: "warning"
                  });
                  return false
                }
              }else if(this.form.min_src!=''){
                if(this.form.min_poster == ''){
                  _this.$message({
                    message: "请上传小设备视频封面",
                    type: "warning"
                  });
                  return false
                }
              }
            }
            if(this.form.src!='' ||this.form.min_src!='' ){
              addData(_this.form).then(res => { 
                if (res.code === 200) {
                  _this.$message({
                    message: "添加成功",
                    type: "success"
                  });
                  _this.form.src=''
                  _this.form.min_poster=''
                  _this.form.min_src=''
                  _this.form.poster=''
                  _this.form.name=''
                  _this.form.type=0
                  _this.form.province_id=0
                  _this.form.city_id=0
                  _this.form.area_id=0
                  _this.form.start_time=''
                  _this.form.end_time=''
                  _this.form.start_date=''
                  _this.form.end_date=''
                  setTimeout(() => {
                    _this.$emit("success");
                  }, 1000);
                }
              })
            }else{
              _this.$message({
                message: "请上传图片/视频",
                type: "warning"
              });
            }
            
          } else {
            return false;
          }
        })
      }
    }
  };
</script>
<style lang="scss" scoped>
  .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow: hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .el-radio-group {
    text-align: left !important;
  }

  .icon-dialog{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .icon-dialog-list{
      box-sizing: border-box;
      padding: 20px 10px;
      width: 16.66%;
      font-size: 32px;
      text-align: center;
      border:1px solid #dbdbdb;
    }
    .icon-dialog-list:hover{
      color:#409eff;
      border-color:#409eff;
    }
  }
  .icon-name{
    margin-top:10px;
    font-size: 12px;
  }
  
</style>
<style>
  .el-dialog__body{
    max-height: 60vh;
    overflow-y: auto;
  }
</style>
