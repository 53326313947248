
    // @ts-ignore
    import {getQiniuToken} from "@/api/public";
    // @ts-ignore
    import qiniuUploader from "@/util/qiniuUploader.js";
    import {Component, Prop, Vue, Emit, Watch} from "vue-property-decorator";

    let that;
    @Component
    export default class upload extends Vue {
        @Prop({default: false}) private multiple!: boolean;
        @Prop({default: 1}) private num!: number;
        @Prop({default: ""}) private path!: string;
        @Prop({default: ""}) private images: any;
        private domin: string = "https://cdn.zd-aichong.com/";
        private actionPath: string = "https://up-cn-east-2.qiniup.com";
        private dialogVisible: boolean = false;
        private disabled: boolean = false;
        private showBtnImg: boolean = true;
        private noneBtnImg: boolean = false;
        private previewImage: string = '';
        private type: string = '';
        private postData: any = {
            token: "",
            key: this.path
        };
        private uploadAddr: string = "https://cdn.zd-aichong.com/";
        private imageUrl: string = "";
        private imgList: Array<any> = [];
        private isUpdated:boolean = false

        public mounted(): void {
            that = this;
            getQiniuToken().then((res: any) => {
                this.postData.token = res.data;
                qiniuUploader.init({
                    region: "ECN",
                    uptoken: "", //你请求后端的uptoken,和上面一样的，uptokenURL不填就找uptoken,填一个就可以了（这里是字符串数据不是url了）
                    domain: 'cloth-bag', //yourBucketId:这个去你域名配置那里要
                    shouldUseQiniuFileName: true,
                    key: this.path + "/"
                });
            });

        }

        public beforeAvatarUpload(file: any): void {
            this.postData.key =
                this.path + "/" + this.createNonceStr() + "." + this.suffix(file.name);
        }

        @Emit("success")
        success(imgList: any) {
            return imgList;
        }

        @Emit("remove")
        sendRemove(imgList: any) {
            return imgList;
        }
        @Emit("preview")
        sendPreview(previewImage: string) {
            return previewImage;
        }


        @Watch("images", {immediate: true, deep: true})
        private watchImageData(newValue: any, oldValue: any) {
          if (typeof newValue === 'string') {
            if( newValue !== ''){
              this.imgList = [{url:newValue,status:"success",percentage: 100}]
              if(newValue.indexOf(".mp4")>-1){
                this.type='video'
              }else{
                this.type = 'img'
              }
            } else{
              this.type="img"
              this.imgList = []
            }
          } else {
            console.log(newValue.length)
            this.imgList = []
            newValue.forEach((value,key) => {
              this.imgList.push({
                url:value,
                status:"success",
                percentage: 100
              })
            })
              console.log(this.imgList)
          }
        }

        public progressUpload(event, file, fileList): void{
          // event.forEach((value,key)=>{
            if(event.percent === 100){
              this.isUpdated = true
            }else{
              this.isUpdated = false;
            }
          // })
        }
        public removeVideo():void{
          this.imgList = []
          this.imageUrl = ''
          this.type='img'
        }
        public handleAvatarSuccess(res: any, file: any,fileList:any): void {
          if(this.isUpdated){
            let images = [];
            if(fileList.length === 1 && fileList[0].response.key.indexOf('.mp4')>-1){
              this.type = 'video'
              images = [this.uploadAddr + fileList[0].response.key]
              this.success(images);
            }else{
              this.type='img'
              fileList.forEach((value,key)=>{
                if(value.status === "success" && value.percentage === 100){
                  this.isUpdated = true
                  if(value.response){
                    images.push(this.uploadAddr + value.response.key)
                  }
                  if(value.url.indexOf(this.uploadAddr)>-1){
                    images.push(value.url)
                  }
                }else{
                  this.isUpdated = false;
                }
              })

              if (this.num == images.length) {
                this.showBtnImg = false;
                this.noneBtnImg = true;
              }
              if(images.length< fileList.length){
                this.isUpdated = false
              }else{
                this.success(images);
              }
            }
          }
        }

        public handlePictureCardPreview(file): void {
            this.previewImage = file.url;
            this.dialogVisible = true;
        }

        public handleRemove(key: any): void {
          console.log(this.imgList)
          let images = []
          if(typeof key === 'number'){
            // this.imgList.forEach((value,k)=>{
            //   if(k !== key){
            //     images.push(value.url)
            //   }else{
            //   }
            // })
          }else{
            this.imgList.forEach((value,k)=>{
              if(value.uid !== key.uid){
                images.push(value.url)
              }else{
              }
            })
          }
          console.log(images)
          this.sendRemove(images);
        }

        public suffix(filename) {
            var index = filename.lastIndexOf(".");
            var ext = filename.substr(index + 1);
            return ext;
        }

        createNonceStr() {
            let chars = [
                "0",
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "A",
                "B",
                "C",
                "D",
                "E",
                "F",
                "G",
                "H",
                "I",
                "J",
                "K",
                "L",
                "M",
                "N",
                "O",
                "P",
                "Q",
                "R",
                "S",
                "T",
                "U",
                "V",
                "W",
                "X",
                "Y",
                "Z",
                "a",
                "b",
                "c",
                "d",
                "e",
                "f",
                "g",
                "h",
                "i",
                "j",
                "k",
                "l",
                "m",
                "n",
                "o",
                "p",
                "q",
                "r",
                "s",
                "t",
                "u",
                "v",
                "w",
                "x",
                "y",
                "z"
            ];
            let nums = "";
            for (let i = 0; i < 16; i++) {
                //这里是几位就要在这里不改变
                let id = Math.round(Math.random() * 61);
                nums += chars[id];
            }
            return nums;
        }
    }
