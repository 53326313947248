<template>
  <section style="padding:0 5%;">
    <el-form
      ref="form"
      label-position="top"
      status-icon
      :model="form"
      :rules="rules"
      label-width="100px"
      style="width:100%;"
    >
      <el-form-item label="内容名称" prop="name" required>
        <el-input v-model="form.name" placeholder="内容名称,不可重复"></el-input>
      </el-form-item>

      <el-form-item label="类型" prop="type" required>
        <el-radio-group v-model="form.type" size="small">
          <el-radio :label="0" border>海报</el-radio>
          <el-radio :label="1" border>视频</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="视频封面" prop="poster" v-if="form.type===1">
        <el-image style="width:100%;height: 200px;" :src="form.poster" fit="contain"></el-image>
      </el-form-item>
      <el-form-item label="展示内容" prop="src">
        <el-image v-if="type==='img'" style="width:100%;height: 200px;" :src="form.src" fit="contain"></el-image>
        <div style="width:100%" v-if="type==='video'">
          <video style="width:100%" :src="form.src" class="video" controls="controls"></video>
        </div>
      </el-form-item>
      <el-form-item label="小设备视频封面" prop="poster" v-if="form.type===1">
        <el-image style="width:100%;height: 200px;" :src="form.min_poster" fit="contain"></el-image>
      </el-form-item>
      <el-form-item label="小设备内容" prop="min_src">
        <el-image v-if="type==='img'" style="width:100%;height: 200px;" :src="form.min_src" fit="contain"></el-image>
        <div style="width:100%" v-if="type==='video'">
          <video style="width:100%" :src="form.min_src" class="video" controls="controls"></video>
        </div>
      </el-form-item>

      <el-form-item label="显示区域" prop="name">
        <districts-cascader :level="3" v-model="pcr" :showAll="true" @choose="handleAreaDistrictsChoose"></districts-cascader>
      </el-form-item>

      <el-form-item label="显示日期范围" prop="start_time">
        <div style="display:inline-flex;width: 100%;">
          <el-date-picker
            v-model="form.start_date"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择开始日期">
          </el-date-picker>
          <span style="margin-left:5px;margin-right:5px;">至</span>
          <el-date-picker
            v-model="form.end_date"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择结束日期">
          </el-date-picker>
        </div>
      </el-form-item>
      <el-form-item label="显示时间范围" prop="start_time">
        <div style="display:inline-flex;width: 100%;">
          <el-time-select
            placeholder="起始时间"
            v-model="form.start_time"
            :picker-options="{
              start: '00:00',
              step: '00:30',
              end: '23:30'
            }">
          </el-time-select>
          <span style="margin-left:5px;margin-right:5px;">至</span>
          <el-time-select
            placeholder="结束时间"
            v-model="form.end_time"
            :picker-options="{
              start: '00:00',
              step: '00:30',
              end: '23:59',
              minTime: form.start_time
            }">
          </el-time-select>
        </div>
      </el-form-item>

      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="submit('form')" :plain="true">立即更改</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
  let _this;
  import {editData,getDataById} from "@/api/media.js";
  import {getListSelect} from "@/api/store.js"
  import UploadQiniu from "@/components/tool/lib/upload/index.vue";
  import DistrictsCascader from "@/components/tool/lib/districts-cascader/Index.vue";
  let form = {
    serial_number: "",
    type: 0,
    store_id: 0,
    agent_id:0,
    battery_count:0,
    express:'',
    express_status:0
  }
  export default {
    props: {
      id: Number
    },
    components: {DistrictsCascader, UploadQiniu},
    data() {
      return {
        form: form,
        type:'',
        rules: {
          name: [
            {required: true, message: "SN不能为空,不可重复", trigger: "blur"},
          ],
          type: [
            {required: true, message: "类型不能为空", trigger: "blur"},
          ],
          src: [
            {required: true, message: "请上传文件", trigger: "blur"},
          ]
        },
        imageUrl: [],
        media_time:[new Date(), new Date(2016, 9, 10, 9, 40)],

        pcr:[],
        service_data:{
          service_ids:[],
          service_ids_name:[],
          service_province:'',
          service_city:'',
          service_area:'',
          service_province_str:'',
          service_city_str:'',
          service_area_str:''
        },
      }
    },

    computed: {},

    mounted() {
      _this = this;
      _this.init();
    },

    methods: {
      // 初始化数据
      init() {
        getDataById(this.id).then(res=>{
          if (res.code === 200){
            this.form = res.data
            this.pcr = [res.data.province_id,res.data.city_id,res.data.area_id]
            var index = res.data.src.lastIndexOf(".");
            var ext = res.data.src.substr(index + 1);
            this.type=ext=='mp4'?'video':'img'
            console.log(this.type)
          }
        })

      },
      handleContentSuccess(e) {
        console.log(e)
        this.form.src = e[0];
      },
      handleContentRemove(e) {
        this.form.src = "";
      },

      handleAreaDistrictsChoose(e){
        this.service_data.service_province = e.province_code
        this.service_data.service_province_str = e.province
        this.service_data.service_city = e.city_code
        this.service_data.service_city_str = e.city
        this.service_data.service_area = e.area_code
        this.service_data.service_area_str = e.area
        this.form.province_id = e.province_code
        this.form.city_id = e.city_code
        this.form.area_id = e.area_code
      },
     
      close() {
        _this.$emit("close");
      },
      submit(form) {
        this.$refs[form].validate(valid => {
          if (valid) {
            console.log(this.form)
            editData(this.id,_this.form).then(res => { 
              if (res.code === 200) {
                _this.$message({
                  message: "更新成功",
                  type: "success"
                });
                this.form = form
                setTimeout(() => {
                  _this.$emit("success");
                }, 1000);
              }
            })
          } else {
            console.log("error submit!!");
            return false;
          }
        })
      }
    }
  };
</script>
<style lang="scss" scoped>
  .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow: hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .el-radio-group {
    text-align: left !important;
  }

  .icon-dialog{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .icon-dialog-list{
      box-sizing: border-box;
      padding: 20px 10px;
      width: 16.66%;
      font-size: 32px;
      text-align: center;
      border:1px solid #dbdbdb;
    }
    .icon-dialog-list:hover{
      color:#409eff;
      border-color:#409eff;
    }
  }
  .icon-name{
    margin-top:10px;
    font-size: 12px;
  }
  
</style>
<style>
  .el-dialog__body{
    max-height: 60vh;
    overflow-y: auto;
  }
</style>
