<template>
  <section>
    <div class="container">
        <div
          class="worker-tooltips"
          style="display:flex;flex-wrap:wrap; width: 100%;justify-content: flex-start"
        >
          <div class="search-box"><h1>订单列表</h1></div>
          <div class="control-box">
            <el-input class="control-box-input" v-model="params.serial_number" style="width:200px;margin-left:10px;margin-top: 10px;" placeholder="请输入订单号" clearable></el-input>
            <el-select v-model="params.status" placeholder="请选择状态" clearable style="margin-left:10px;width:150px;margin-top: 10px;">
              <el-option
                v-for="item in orderType"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-select v-model="params.group_id"  placeholder="请选择需搜索公司" filterable clearable style="margin-left:10px;margin-top:10px;" v-if="groupID===1 && params.order_type===0" >
              <!-- <el-option label="请选择需搜索公司">请选择需搜索公司</el-option> -->
              <el-option
                v-for="item in groupList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <el-select v-model="params.agent_id"  placeholder="请选择需搜索代理商" filterable clearable style="margin-left:10px;margin-top:10px;" v-if="params.order_type===0" >
              <!-- <el-option label="请选择需搜索公司">请选择需搜索公司</el-option> -->
              <el-option
                v-for="item in agentList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <el-select v-model="params.store_id" placeholder="请选择需搜索投放点" filterable clearable style="margin-left:10px;margin-top:10px;" v-if="params.order_type===0">
              <!-- <el-option label="请选择需搜索投放点">请选择需搜索投放点</el-option> -->
              <el-option
                v-for="item in storeList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <el-select v-model="params.channel" placeholder="请选择需搜索支付渠道" filterable clearable style="margin-left:10px;margin-top:10px;">
              <!-- <el-option label="请选择需搜索投放点">请选择需搜索投放点</el-option> -->
              <el-option
                v-for="item in payList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-input class="control-box-input" v-model="params.phone" style="margin-left:10px;margin-top:10px;" placeholder="请输入手机号" clearable></el-input>
            <el-date-picker
              v-model="params.start_date"
              type="date" style="margin-left:10px;margin-top:10px;"
              format="yyyy-MM-dd 日"
              value-format="yyyy-MM-dd"
              placeholder="请选择开始日期"
              v-if="groupID===1">
            </el-date-picker>
            <el-date-picker
              v-model="params.end_date"
              type="date" style="margin-left:10px;margin-top:10px;"
              format="yyyy-MM-dd 日"
              value-format="yyyy-MM-dd"
              placeholder="请选择结束日期"
              v-if="groupID===1">
            </el-date-picker>
            <el-select v-model="params.type" placeholder="请选择需搜索信用类型" filterable clearable style="margin-left:10px;margin-top:10px;" v-if="params.order_type===0">
              <!-- <el-option label="请选择需搜索投放点">请选择需搜索投放点</el-option> -->
              <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-input class="control-box-input" v-model="params.price" style="margin-left:10px;margin-top:10px;" type="number" placeholder="请输入金额" clearable></el-input>
            <el-input class="control-box-input" v-model="params.market" style="margin-left:10px;margin-top:10px;" placeholder="请输入市场负责人" clearable></el-input>
            <el-button icon="el-icon-search" @click="searchData" style="margin-left:10px;margin-top: 10px;">搜索</el-button>
          </div>
        </div>
      <!--订单列表-->
      
      <el-tabs v-model="activeName" @tab-click="handleClick" style="margin-top:10px;padding-left:30px;">
          <el-tab-pane label="订单" name="default"></el-tab-pane>
          <el-tab-pane label="提现" name="cash"></el-tab-pane>
        </el-tabs>
      <el-table :data="table" class="table" width="1000" :row-class-name="tableRowClassName">

        <!-- 数据展示区 -->
        <el-table-column
          label="订单状态"
          prop="status"
          align="center"
          width="150"
          fixed="left"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.status===0">初始化订单</span>
            <span v-if="scope.row.status===1">已付押金/确认授权</span>
            <span v-if="scope.row.status===2">借用成功</span>
            <span v-if="scope.row.status===3">归还成功，未结算</span>
            <span v-if="scope.row.status===4">扣款成功/结束订单</span>
            <span v-if="scope.row.status===5">订单失败</span>
            <span v-if="scope.row.status===8" style="color:#e0a118">免单用户/取消订单</span>
            <span v-if="scope.row.status===9">取消订单</span>
          </template>
        </el-table-column>
        <el-table-column label="投放点" prop="store_id"  align="center" width="200" v-if="params.order_type===0" fixed="left">
          <template slot-scope="scope">
            <span v-if="scope.row.store_id===0">投放点未绑定</span>
            <el-popover trigger="hover" placement="top" v-if="scope.row.store">
              <p>投放点: {{ scope.row.store.name }}</p>
              <div slot="reference" class="name-wrapper">
                <el-tag>{{scope.row.store.name}}</el-tag>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          label="付款金额"
          prop="price"
          align="center"
          width="140"
        ></el-table-column>
       
        <el-table-column
          label="使用总分数"
          prop="time"
          align="center"
          width="120"
        >
        <template slot-scope="scope">
          <span>
            {{ Math.floor(scope.row.time) > 0 ? Math.floor(scope.row.time)+'小时' :''}}
            {{Math.round((scope.row.time-Math.floor(scope.row.time))*60*10)/10+'分钟'}}
          </span>
        </template>
        </el-table-column>
        <el-table-column
          label="创建时间"
          prop="created_at"
          align="center" width="200"
        ></el-table-column>
        <el-table-column label="订单结束时间" align="center" width="260">
          <template slot-scope="scope">
            <!-- <span v-if="scope.row.status==1">授权时间：{{ scope.row.updated_at }}</span>
            <span v-if="scope.row.status==2">弹出时间：{{ scope.row.updated_at }}</span>
            <span v-if="scope.row.status==3">归还时间：{{ scope.row.updated_at }}</span> -->
            <span v-if="scope.row.status===4||scope.row.status===8">{{ scope.row.updated_at }}</span>
            <!-- <span v-if="scope.row.status==9">取消时间：{{ scope.row.updated_at }}</span> -->
          </template>
        </el-table-column>
        
        <el-table-column
          label="订单号"
          prop="serial"
          align="center"
          width="200"
        ></el-table-column>
        <el-table-column label="所属公司" prop="group.name" align="center" width="150" v-if="groupID===1">
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="top" v-if="scope.row.group">
              <p v-if="scope.row.agent">所属公司: {{ scope.row.group.name }}</p>
              <div slot="reference" class="name-wrapper" style="width-space:nowrap;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">
                <span>{{scope.row.group.name}}</span>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          label="所属代理商"
          prop="agent"
          align="center"
          width="180"
          v-if="params.order_type===0"
        >
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="top" v-if="scope.row.agent">
              <p v-if="scope.row.agent">代理商: {{ scope.row.agent.name }}</p>
              <div slot="reference" class="name-wrapper" style="width-space:nowrap;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">
                <span>{{scope.row.agent.name}}</span>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          label="支付渠道"
          prop="channel_zh"
          align="center"
        >
        </el-table-column>
        <el-table-column label="设备" prop="device_id" align="center" width="200" v-if="params.order_type===0">
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="top" v-if="scope.row.device_id!=0">
              <p>设备: {{ scope.row.device.serial_number }}</p>
              <div slot="reference" class="name-wrapper">
                <p>设备: {{ scope.row.device.serial_number }}</p>
              </div>
            </el-popover>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column label="充电宝序列号" prop="device_slot" align="center" width="200" v-if="params.order_type===0">
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="top">
              <p>设备: {{ scope.row.name }}</p>
              <div slot="reference" class="name-wrapper">
                <el-tag>{{scope.row.device_slot}}</el-tag>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <!-- <el-table-column
          label="借用类型"
          prop="type_zh"
          align="center"
          width="100"
          v-if="params.order_type===0"
        >
        </el-table-column> -->
        <el-table-column label="用户" prop="user_id" align="center" width="120">
          <template slot-scope="scope">
            <span>{{scope.row.user.phone}}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="取消订单理由"
          prop="reason"
          align="center"
          width="200"
        ></el-table-column>

        <!-- <el-table-column label="时间" prop="time" align="center" >
          <template slot-scope="scope">
            <span>{{parseInt(scope.row.time*60)}}分钟</span>
          </template>
        </el-table-column> -->

        <el-table-column label="操作" align="center" width="100" fixed="right">
          <template slot-scope="scope">
            <div

            >
              <el-dropdown @command="command($event,scope.row)">
                  <span class="el-dropdown-link" style="color: #409eff">
                    更多操作
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="cancelOrder" v-if="admin_permission.indexOf('update')>-1" divided>取消订单</el-dropdown-item>
                  <el-dropdown-item command="remove" v-if="admin_permission.indexOf('deleted')>-1" divided>删除订单</el-dropdown-item>
                  <el-dropdown-item command="pay" divided>订单扣款</el-dropdown-item>
                  <el-dropdown-item command="getAli" v-if="scope.row.channel === 1">支付宝订单查询</el-dropdown-item>
                  <!-- <el-dropdown-item command="pay" v-if="scope.row.status===2&&scope.row.status===3" divided>订单扣款</el-dropdown-item> -->
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>


      <el-dialog title="更改订单状态" width="500px" :visible.sync="statusVisible">
        <el-form :model="formStatus" label-position="top">
          <el-form-item label="订单状态" required>
            <el-select
              v-model="formStatus.status"
              clearable
              placeholder="请选择状态"
            >
              <el-option
                v-for="item in orderType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="statusVisible = false" class="cancel-btn">取 消</el-button>
          <el-button type="primary" @click="changeStatus">更改</el-button>
        </div>
      </el-dialog>
      <el-dialog title="取消订单" width="500px" :visible.sync="reasonShow">
        <div>请输入取消理由</div>
        <el-input v-model="reason" placeholder="请输入理由" style="margin-top:20px;"></el-input>
        <div slot="footer" class="dialog-footer">
          <el-button @click="reasonShow = false" class="cancel-btn">取 消</el-button>
          <el-button type="primary" @click="cancelReason()">确认</el-button>
        </div>
      </el-dialog>

      <el-dialog title="订单详情" width="800px" :visible.sync="orderDetailShow">
        <el-descriptions class="margin-top" v-if="orderDetailShow" :column="2" size="medium " border>
          <template slot="title">
            {{ orderDetail.alipay_fund_auth_operation_detail_query_response.out_order_no }} 
            <el-tag v-if="orderDetail.order.channel === 1" effect="dark" size="small">支付宝</el-tag>
            <el-tag v-if="orderDetail.order.channel === 0" effect="dark" type="success" size="small">微信</el-tag>
          </template>
          <template slot="extra"  v-if="orderDetail.alipay_fund_auth_operation_detail_query_response.order_status !== 'CLOSED' && orderDetail.alipay_fund_auth_operation_detail_query_response.order_status !== 'FINISH'">
            <el-button type="primary" size="small" @click="endAliOrder" >解冻剩余资金并结束订单</el-button>
          </template>

          <el-descriptions-item>
            <template slot="label">
              订单号
            </template>
            {{orderDetail.alipay_fund_auth_operation_detail_query_response.out_order_no}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              支付宝资金授权订单号
            </template>
            {{ orderDetail.alipay_fund_auth_operation_detail_query_response.auth_no }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              授权单状态
            </template>
            <span v-if="orderDetail.alipay_fund_auth_operation_detail_query_response.order_status === 'INIT'">已创建未授权</span>
            <span v-if="orderDetail.alipay_fund_auth_operation_detail_query_response.order_status === 'AUTHORIZED'">授权成功</span>
            <span v-if="orderDetail.alipay_fund_auth_operation_detail_query_response.order_status === 'FINISH'">转支付完成</span>
            <span v-if="orderDetail.alipay_fund_auth_operation_detail_query_response.order_status === 'CLOSED'">关闭状态</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              冻结总金额
            </template>
            {{ orderDetail.alipay_fund_auth_operation_detail_query_response.total_freeze_amount }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              剩余冻结金额
            </template>
            {{ orderDetail.alipay_fund_auth_operation_detail_query_response.rest_amount }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              订单金额
            </template>
            {{ orderDetail.order.price }}
          </el-descriptions-item>
        </el-descriptions>
      </el-dialog>

      <el-dialog title="订单扣款" width="500px" :visible.sync="payShow">
        
        <div style="display: flex;justify-content: flex-start;align-items: center;margin-top:20px;">
          <div>请选择订单结束时间：</div>
          <el-date-picker
            style="margin-left:10px"
            v-model="end_time"
            type="datetime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择订单结束的日期时间">
          </el-date-picker>
        </div>
        
        <div slot="footer" class="dialog-footer">
          <el-button @click="payShow = false" class="cancel-btn">取 消</el-button>
          <el-button type="primary" @click="cancelPay()">确认</el-button>
        </div>
      </el-dialog>

      <!--分页-->
      <el-col class="toolbar">
        <div class="word">订单总额:  <span>{{total_amount}}</span></div>
        <el-pagination
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          :current-page.sync="params.page"
          @prev-click="prevpage"
          @next-click="nextpage"
          :page-size="10"
          :total="total"
          style="float: right;flex-shrink: 1;"
        ></el-pagination>
      </el-col>
    </div>
  </section>
</template>

<script>
// import { getCascader } from "@/api/provider.js";
import { getTable, editData,deleteData,cancelData,getPay,syncAli,getAli,unfreezeAli} from "@/api/order.js";
import { getListSelect} from "@/api/store.js";
import { getGroups} from "@/api/system.js";
import { getAgentListSelect} from "@/api/agent.js";

let page_name = 'order_list'
export default {
  name: "App",
  data() {
    return {
      admin_permission:[],
      reasonShow:false,
      payShow:false,
      orderDetailShow:false,
      orderDetail:'',
      reason:'',
      end_time:'',//订单结束时间
      activeName:'',
      outerVisible:false,
      params: {
        page:1,
        serial_number:'',
        status:'',
        group_id:'',
        store_id:'',
        agent_id:'',
        channel:'',
        type:'',
        order_type:0,
        phone:'',
        start_date:'',
        end_date:'',
        market:""
      },
      formPrice:{id:0,price:''},
      priceVisible:false,
      statusVisible:false,
      formStatus:{id:0,status:''},
      cancelId:'',
      payId:'',//扣款订单id
      cascaderOpthion:[],
      cascaderValue:'',
      table: [],
      total: 0,
      total_amount:0,
      groupList:[],
      storeList:[],
      agentList:[],
      groupID:'',
      showBack: false,
      orderType:[
        {
          value: 0,
          label: "初始化",
        },
        {
          value: 1,
          label: "已付押金/确认授权",
        },
        {
          value: 2,
          label: "借用成功",
        },
        {
          value: 3,
          label: "归还成功，未结算",
        },
        {
          value: 4,
          label: "扣款成功/结束订单",
        },
        {
          value: 5,
          label: "订单失败",
        },
        {
          value: 8,
          label: "免单用户/取消订单",
        },
        {
          value: 9,
          label: "取消订单",
        }
      ],
      payList:[
        {
          value: 0,
          label: "微信",
        },
        {
          value: 1,
          label: "支付宝",
        },
      ],
      typeList:[
        {
          value: 0,
          label: "信用分",
        },
        {
          value: 1,
          label: "押金",
        },
        {
          value: 2,
          label: "提现",
        },
      ]
    };
  },
  methods: {
    // 获取订单列表
    getTable() {
      getTable(this.params).then((res) => {
        if (res.code === 200) {
          console.log(res.data)
          this.table = res.data.data
          this.total = res.data.total;
          this.total_amount = res.data.total_amount
        }
      });
    },
    tableRowClassName({row, rowIndex}) {

      if (row.white === []) {
        return '';
      } else if (row.white.type === 0) {
        return 'success-row';
      } else if (row.white.type === 1) {
        return 'danger-row';
      } else if (row.white.type === 2) {
        return 'warning-row';
      } else if (row.white.type === 3) {
        return 'info-row';
      }
      return '';
    },
    handleClick(tab, event) {
      if(this.activeName === 'default'){
        this.params.order_type = 0
        this.params.page = 1
      }
      if(this.activeName === 'cash'){
        this.params.order_type = 1
        this.params.page = 1
      }
      this.getTable()
    },
    searchData(){
      this.params.page=1
      console.log(this.params)
      this.getTable()
    },
    endAliOrder(){
      this.$confirm('结束订单后无法恢复，确认结束订单吗？').then(_ => {
        let id = this.orderDetail.order.id
        let amount = this.orderDetail.alipay_fund_auth_operation_detail_query_response.rest_amount
        unfreezeAli(id,{amount:amount}).then((res) =>{
          if(res.code === 200){
            this.$message.success('解冻成功')
            this.getTable()
          }
        })
      })
      .catch(_ => {});
    },
    command(e,row){
      if(e === 'cancelOrder'){
        this.cancelId = row.id
        console.log(row)
        this.reasonShow = true
      }
      if(e === 'status'){
        this.formStatus.id = row.id
        this.formStatus.status = row.status
        this.statusVisible = true
      }
      if(e === 'pay'){
        this.payId = row.id
        console.log(row)
        this.payShow = true
      }
      if(e === 'syncAli'){
        syncAli(row.id,{}).then((res) =>{
          if(res.code === 200){
            this.$message({
              message: res.msg,
              type:'success'
            })
            this.getTable()
          }
        })
      }
      if(e === 'getAli'){
        this.orderDetailShow = true
        getAli(row.id,{}).then(res=>{
          if(res.code === 200){
            console.log(res);
            this.orderDetail = res.data
            this.orderDetail['order'] = row
          }
        })
      }
      if(e === 'remove'){
        this.$confirm('删除后无法恢复，请慎重考虑，确认删除数据吗？')
          .then(_ => {
            deleteData(row.id).then(res=>{
              this.getTable()
              this.$notify({
                title: '成功',
                message: '删除成功',
                type: 'success'
              });
            })
          })
          .catch(_ => {});
        }
    },
    changeStatus(){
      editData(this.formStatus.id,{'status':this.formStatus.status}).then(res=>{
        if (res.code === 200){
          this.getTable()
          this.$message({
            type: 'success',
            message: '更改成功!'
          });
          this.statusVisible = false
        }
      })
    },
    //取消订单确认
    cancelReason(){
      console.log(this.reason)
      cancelData(this.cancelId,{'reason':this.reason}).then(res=>{
        if (res.code === 200){
          this.getTable()
          this.$message({
            type: 'success',
            message: '已取消!'
          });
          this.reasonShow = false
        }
      })
    },
    //扣款订单确认
    cancelPay(){
      console.log(this.end_time)
      if(!this.end_time){
        this.$message({
          type: 'error',
          message: '请选择时间'
        });
      }else{
        getPay({'id':this.payId,'end_time':this.end_time}).then(res=>{
          if (res.code === 200){
            this.getTable()
            this.$message({
              type: 'success',
              message: '已发起扣款!'
            });
            this.payShow = false
          }
        })
      }
      
    },
    // 查询
    getOrderType(type) {
      console.log(type)
      this.params.status = type;
    },
    getOrderNum(number) {
      this.params.order_number = number;
    },
    handleBack() {
      this.showBack = false;
      this.params = {};
      this.getTable();
    },

    // 删除订单
    handleDelete(index, row) {
      this.$confirm("确认删除？")
        .then((_) => {
          deleteOrder(row.id, { deleted: 1 })
            .then((data) => {
              if (data.code === 200) {
                this.getOrderInfo();
              }
            })
            .catch((error) => {});
        })
        .catch((_) => {});
    },

    // 分页
    prevpage() {
      this.params.page -= 1;
      this.getTable()
    },
    nextpage() {
      this.params.page += 1;
      this.getTable()
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.getTable()
    },
  },
  mounted() {
    this.admin_permission = this.$store.state.permission.permissionArr[page_name]
    this.getTable();
    this.groupID = this.$store.state.user.userData.group_id
    
    getGroups().then(res=>{
      if(res.code === 200){
        this.groupList = res.data
      }
    })
    getListSelect().then(res=>{
      if(res.code === 200){
        this.storeList = res.data
      }
    })
    getAgentListSelect().then(res=>{
      if(res.code === 200){
        this.agentList = res.data
      }
    })
  },
};
</script>

<style scoped lang="scss">
@import "@/static/default";
.temple {
  width: 100%;
  background-color: white;
  padding: 10px 10px;
  // padding-left: 50px;
}
.order {
  width: 100%;
  background-color: white;
  padding: 10px 50px;
  // padding-left: 50px;
}
.container {
  background-color: #fff !important;
  padding: 0 32px;
  .table {
    margin-top: 24px !important;
  }
  .toolbar {
    background: #fff;
    width: calc(100% + 64px);
    padding: 16px 32px 26px 0;
    margin-left: -32px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .word{
      margin-right:50px;
      font-size:14px;
      span{
        font-size:18px;
      }
    }
  }
  .search-box{
    margin-top:20px;
    padding-left:20px;
  }
  .control-box{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    padding-left:20px;
    padding-top:10px;
    &-input{
      width: 150px;
    }
  }
}

.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
<style>
  .el-table .warning-row {
    background: rgb(250, 236, 216);
  }

  .el-table .success-row {
    background: rgb(225, 243, 216);
  }

  .el-table .danger-row {
    background: rgb(253, 226, 226);;
  }

  .el-table .info-row {
    background: rgb(233, 233, 235);
  }
</style>
