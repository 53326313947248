<template>
  <section style="padding:0 5%;">
    <el-form
      ref="form"
      label-position="top"
      status-icon
      :model="form"
      label-width="100px"
      style="width:100%;"
    >
      <!-- <el-divider content-position="left">商户基本信息</el-divider> -->
      <el-form-item label="代理商名称" prop="nickname" required>
        <el-input v-model="form.nickname" placeholder="请输入代理商名称"></el-input>
      </el-form-item>
      <el-form-item label="身份证号" prop="idcard_no" required>
        <el-input v-model="form.idcard_no" placeholder="请输入身份证号"></el-input>
      </el-form-item>
      <el-form-item label="联系方式" prop="phone" required>
        <el-input placeholder="请输入代理商电话" v-model="form.phone"></el-input>
      </el-form-item>
      <el-form-item label="分润" prop="devided" required>
        <el-input placeholder="请输入分润比例" v-model="form.devided">
          <template slot="append">%</template>
        </el-input>
      </el-form-item>
      <el-form-item label="绑定企业" prop="group_id" required v-if="groupID===1">
        <el-select v-model="form.group_id" placeholder="请绑定企业" filterable clearable>
          <el-option label="请绑定企业" :value="0">请绑定企业</el-option>
          <el-option
            v-for="item in groupList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      
      <!-- <el-form-item label="单价" prop="unit_price" required>
        <el-input v-model="form.unit_price" placeholder="请输入单价"></el-input>
      </el-form-item>
      <el-form-item label="单位" prop="unit" required>
        <el-select v-model="form.unit" clearable filterable placeholder="请选择单位">
          <el-option
            v-for="item in unitList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item> -->

      <el-form-item label="上传身份证正面">
        <upload-qiniu @success="handlePicSuccess" @remove="handlePicRemove" :num="1" path="store" :multiple="true" :images="storeImageUrl"></upload-qiniu>
      </el-form-item>
      <el-form-item label="上传身份证反面">
        <upload-qiniu @success="handleLicenseSuccess" @remove="handlePicRemove" :num="1" path="store" :multiple="true" :images="imageUrl"></upload-qiniu>
      </el-form-item>
      <el-form-item label="备注" prop="comment">
        <el-input placeholder="请输入备注" type="textarea" v-model="form.comment"></el-input>
      </el-form-item>

      
      <!-- <el-divider content-position="left">商户服务信息</el-divider>
      <el-form-item>
        <el-button @click="innerVisible = true">添加服务信息</el-button>
        <div v-for="(value,key) in form.service_data" :key="key">
          <el-tag closable @close="removeServiceData(key)">{{value.service_ids_name[0]}} {{value.service_ids.length>1 ? '+'+value.service_ids.length : ''}} {{value.service_province_str+value.service_city_str+value.service_area_str}}</el-tag>
        </div>
      </el-form-item> -->
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="editData()" :plain="true">立即修改</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>

  let _this;
  import {getListById,editData} from "@/api/agent.js";
  import {tencentAddressKeyword,areaDetailCode} from "@/api/public.js";
  import { getGroups} from "@/api/system.js";
  import UploadQiniu from "@/components/tool/lib/upload/index.vue";
  import DistrictsCascader from "@/components/tool/lib/districts-cascader/Index.vue";

  import Picture from '@/components/tool/lib/upload/index.vue';

  export default {
    props: {
      id: Number
    },
    components: {DistrictsCascader, UploadQiniu, Picture},
    data() {
      return {
        groupList:[],
        unitList: [{
          value: 0,
          label: '每半小时'
        }, {
          value: 1,
          label: '每一小时'
        }],
        toolsConfig: ["districts"],
        keywordAddress: [],
        keywordLoading: false,
        keywordResult: "",
        keywordProvince:'',
        
        form: {
          nickname: "",
          phone: "",
          group_id:'',
          idcard_no: '',
          idcard_image:'',
          idcard_back:'',
          devided:'',
          comment:''
        },
        imageUrl: [],
        storeImageUrl:[],
        value: [],
        time: '',
        serviceCascader: [],
        serviceCascaderProps: {
          multiple: true,
          value:'id',
          label:'label'
        },
        serviceCascaderValue:[],
        service_data:{
          service_ids:[],
          service_ids_name:[],
          service_province:'',
          service_city:'',
          service_area:'',
          service_province_str:'',
          service_city_str:'',
          service_area_str:''
        },
        show: true,
        placeholder: "",
        innerVisible:false
      };
    },

    computed: {},

    mounted() {
      _this = this;
      _this.groupID = _this.$store.state.user.userData.group_id
      _this.init();

    },

    methods: {
      // 初始化数据
      init() {
        console.log(this.id)
        getListById(this.id).then(res=>{
          if(res.code === 200){
            this.form = res.data
            // console.log(res.data)
            this.keywordResult = res.data.address
            console.log(this.form.store_images)
          }
        })
        getGroups().then(res=>{
          if(res.code === 200){
            this.groupList = res.data
          }
        })
      },
      remoteMethod(query) {
        if (query !== "") {
          this.keywordLoading = true;
          tencentAddressKeyword({keyword: query}).then(res => {
            this.keywordLoading = false;
            if (res.code === 200) {
              this.keywordAddress = res.data;
            }
          });
        } else {
          this.options = [];
        }
      },

      keywordChange() {
        let data = this.keywordAddress[this.keywordResult];
        this.keywordProvince = data['address']+data['title']
        areaDetailCode({area_id: data.adcode}).then(res => {
          if (res.code === 200) {
            this.form.province_id = res.data.province_id
            this.form.city_id = res.data.city_id
            this.form.area_id = res.data.area_id
            this.form.lat = data["location"]["lat"];
            this.form.lng = data["location"]["lng"];
            this.form.address = data['address']+data['title']
          }
        })
      },

      serviceCascaderChange(e){
        let ids = []
        let names = []
        let object = this.$refs["service_cascader"].getCheckedNodes()
        Object.keys(object).map((key) => [object[key], key]).forEach(([value,key]) => {
          //循环操作
          if(!value.hasChildren){
            ids.push(value.value)
            names.push(value.label)
          }
        })
        this.service_data.service_ids = ids
        this.service_data.service_ids_name = names
      },

      close() {
        _this.$emit("close");
      },

      editData() {
        console.log(_this.form)
        editData(_this.id, _this.form).then((res) => {
          if (res.code === 200) {
            _this.$message({
              message: "修改成功",
              type: "success"
            });
            setTimeout(() => {
              _this.$emit("success");
            }, 1000);
          }
        });
      },
      addServiceData(){
        this.form.service_data.push(JSON.parse(JSON.stringify(this.service_data)));
        this.serviceCascaderValue = []
        this.innerVisible = false
      },
      // 选择省市区
      handleDistrictsChoose(e) {
        _this.form.province_id = e.province_code;
        _this.form.city_id = e.city_code;
        _this.form.area_id = e.area_code;
      },
      handleAreaDistrictsChoose(e){
        this.service_data.service_province = e.province_code
        this.service_data.service_province_str = e.province
        this.service_data.service_city = e.city_code
        this.service_data.service_city_str = e.city
        this.service_data.service_area = e.area_code
        this.service_data.service_area_str = e.area
      },
      // 上传营业执照删除、缓存、预览
      handleLicenseSuccess(e) {
        console.log(e)
        this.form.idcard_back = e[0];
      },
      handleLicenseRemove(e) {
        this.form.idcard_back = "";
      },
      // 上传图片删除、缓存、预览
      handlePicSuccess(e) {
        console.log(e)
        this.form.idcard_image = e[0];
      },
      handlePicRemove(e) {
        this.form.idcard_image = '';
      },


      removeServiceData(key){
        console.log(key)
        let object = JSON.parse(JSON.stringify(this.form.service_data))
        console.log(object)
        if(object.length ===1){
          object = []
        }else{
          object.splice(key,1)
        }
        this.form.service_data = object
      }
    }
  };
</script>
<style lang="scss" scoped>
  .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow: hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .el-radio-group {
    text-align: left !important;
  }
</style>
