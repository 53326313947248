<template>
  <section style="height: 100%">
    <div class="container">
      <el-main style="width: 100%;box-sizing: border-box;padding-left:20px;">
        <div
          class="worker-tooltips"
          style="display:flex;flex-wrap:wrap; width: 100%;justify-content: flex-start"
        >
          <div class="search-box"><h1>定价列表</h1></div>
          <!-- <div class="control-box" style="margin-top: 10px;">
            <el-input class="control-box-input" v-model="params.name" placeholder="请输入搜索名字"></el-input>
            <el-button icon="el-icon-search" @click="getTableData" style="margin-left: 10px;">搜索</el-button>
            <el-button icon="el-icon-plus" type="primary" @click="handleOpenAdd" v-if="admin_permission.indexOf('add')>-1">添加定价</el-button>
          </div> -->
        </div>
        <!-- <el-tabs v-model="activeName" @tab-click="handleClick" style="margin-top:10px;">
          <el-tab-pane label="全部" name="all"></el-tab-pane>
          <el-tab-pane label="已通过审核" name="pass"></el-tab-pane>
          <el-tab-pane label="未通过审核" name="refuse"></el-tab-pane>
        </el-tabs> -->
        <el-table
          :data="table"
          style="width: 100%;"
          tooltip-effect="dark"
          empty-text="暂无数据"
          class="table"
        >
          <el-table-column label="id" prop="id" align="left" width="70"></el-table-column>
          <el-table-column label="提请时间" prop="store.created_at" align="center"></el-table-column>
          <el-table-column label="投放点名称" prop="store.name" align="left" width="140"></el-table-column>
          <el-table-column label="时间单位" prop="unit" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.unit ===0">每半小时</span>
              <span v-if="scope.row.unit ===1">每一小时</span>
            </template>
          </el-table-column>
          <el-table-column label="单价" prop="price" align="center"></el-table-column>
          <el-table-column label="状态" prop="status" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.status ===0">未审核</span>
              <span v-if="scope.row.status ===1">已通过审核</span>
              <span v-if="scope.row.status ===2">审核已拒绝</span>
            </template>
          </el-table-column>

          <el-table-column label="操作" align="center" fixed="right" width="70">
            <template slot-scope="scope">
              <div
                style="
                  width: 100%;
                  display: inline-flex;
                  justify-content: center;
                "
              >
                <el-dropdown @command="command($event,scope.row)">
                  <span class="el-dropdown-link" style="color: #409eff">
                    <el-tooltip class="item" effect="dark" content="更多操作" placement="top-start">
                      <i class="el-icon-arrow-down" style="font-size:16px;"></i>
                    </el-tooltip>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="appointment" v-if="(scope.row.status===0) && admin_permission.indexOf('update')>-1">通过审核</el-dropdown-item>

                    <el-dropdown-item command="refuse" divided  v-if="(scope.row.status===0) && admin_permission.indexOf('update')>-1">拒绝通过</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="width:100%;display:flex;justify-content: flex-end;box-sizing: border-box;padding: 10px;">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="params.page"
            :hide-on-single-page="false"
            :small="true"
            :page-size="params.page_count"
            layout="total, prev, pager, next,jumper"
            :total="total">
          </el-pagination>
        </div>
      </el-main>
    </div>

    <el-dialog title="定价详情" v-if="dialogRefuseVisible" :visible.sync="dialogRefuseVisible">
      <div style="margin-bottom: 20px;">拒绝理由</div>
      <el-input v-model="reason" placeholder="请输入拒绝理由"></el-input>
      <div style="display: flex;justify-content: space-around;width:200px;margin:30px auto 10px auto;">
        <el-button>取消</el-button>
        <el-button type="primary" @click="confirm()">确定</el-button>
      </div>
      
    </el-dialog>
    <el-drawer
      title="更改投放点类型"
      :visible.sync="editDrawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <edit-comp v-if="editDrawer" @close="handleClose" @success="handleDrawerSuccess" :id="editID" ref="newForm"></edit-comp>
    </el-drawer>

    <!-- 添加规则 -->
    <el-drawer
      title="添加投放点类型"
      :visible.sync="addDrawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <add-comp
        @close="handleClose"
        @success="handleDrawerSuccess"
        @clear="clearContent"
        ref="newForm"
        v-if="addDrawer"
      ></add-comp>
    </el-drawer>
  </section>
</template>

<script>
  import { storePriceAppointment, storePriceRefuse,getHistoryList} from "@/api/store.js";

  import addComp from "./components/add.vue";
  import editComp from "./components/edit.vue";

  let page_name = 'store_type'
  export default {
    name: "App",
    components:{addComp,editComp},
    data() {
      return {
        admin_permission:[],
        status: [
          {id:"",label:"全部"},
          {id:0,label:"审核中"},
          {id:1,label:"审核通过"},
          {id:2,label:"审核失败"},
        ],
        treeCurrentNode:"",
        dialogRefuseVisible:false,
        params: {
          page: 1,
          page_count: 10,
        },

        table:[],
        refuseId:'',
        reason:'',
        service: [],
        editID: '',
        total: 0,
        addDrawer: false,
        editDrawer: false,
        direction: "rtl",
        visible: false,
        scopeForm:{
          id:'',
          sort: ''
        }
      };
    },

    methods: {
      getTableData() {
        getHistoryList(this.params).then(res=>{
          if(res.code === 200){
            console.log(res.data)
            this.table = res.data.data
            console.log(this.table)
            this.params.page = res.data.current_page
            this.params.page_count = res.data.per_page
            this.total = res.data.total
          }
        })
      },

      writeScope(id){
        this.scopeForm.id = id
        let tmp = [];
        this.service.forEach((value,key)=>{
          tmp[key] = value
          if (id === value.id){
            this.scopeForm.sort = parseInt(value.sort)
            tmp[key].write_scope = true
          }
        })
        this.service = tmp;
      },

      scopeChange(){
        editData(this.scopeForm.id,{sort:this.scopeForm.sort}).then(res=>{
          if (res.code === 200){
            this.searchSet()
          }
        })
      },
      
      handleClick(tab, event) {
        this.params.page=1
        if(this.activeName === 'all'){
          this.params.status = ''
        }
        if(this.activeName === 'pass') this.params.status = 1
        if(this.activeName === 'refuse') this.params.status = 2
        this.getTableData()
      },
      command(e,item){
          console.log(e)
        if(e === 'refuse'){
          this.refuseId = item.id
          this.dialogRefuseVisible =true
        }
        if(e === 'appointment'){
          storePriceAppointment(item.id).then(res=>{
            if(res.code===200){
              this.getTableData()
              this.$notify({
                title: '成功',
                message: '已通过',
                type: 'success'
              });
            }
          })
        }
      },
      confirm(){
        if(this.reason === ''){
          this.$message({
            message: "请输入拒绝理由",
            type: "success"
          });
          return false
        } 
        storePriceRefuse(this.refuseId,{reason:this.reason}).then(res=>{
          if(res.code === 200){
            this.dialogRefuseVisible = false
            this.getTableData()
            this.$notify({
              message: '已拒绝',
            });
          }
        })
      },
      cancel(){
        this.dialogVisible=false
        this.dialogRefuseVisible=false
        this.dialogPerVisible=false
      },
      refuse(id){
        this.refuseId = id
        this.dialogRefuseVisible =true
      },
      //选择TREE
      showTreeWorker(id, pid) {
          this.params.status = id;
          this.getTableData();
      },

      // 打开添加
      handleOpenAdd() {
        this.addDrawer = true;
      },
      // 打开修改
      handleEdit(id) {
      },
      handleDelete(id) {
        this.$confirm('删除后无法恢复，请慎重考虑，确认删除数据吗？')
          .then(_ => {
            deleteType(id).then(res=>{
              this.getTableData()
              this.$notify({
                title: '成功',
                message: '删除成功',
                type: 'success'
              });
            })
          })
          .catch(_ => {});
      },
      // 关闭添加/修改
      handleClose() {
        this.$confirm("确认关闭？")
          .then((_) => {
            this.addDrawer = false;
            this.editDrawer = false;
          })
          .catch((_) => {});
      },
      // 添加/修改成功
      handleDrawerSuccess() {
        this.addDrawer = false;
        this.editDrawer = false;
        this.getTableData()
        this.$refs.newForm.$refs.form.resetFields();
      },
      // 清空子组件
      clearContent() {
        this.$refs.newForm.$refs.form.resetFields();
      },
      // 分页
      prevpage() {
        this.params.page -= 1;
        this.getTableData();
      },
      nextpage() {
        this.params.page += 1;
        this.getTableData();
      },
      handleCurrentChange(val) {
        this.params.page = val;
        this.getTableData();
      },
    },
    mounted() {
      this.admin_permission = this.$store.state.permission.permissionArr[page_name]
      console.log(this.admin_permission)
      this.getTableData();
    }
  };
</script>

<style scoped lang="scss">
  @import "@/static/default";

  .container {
    width: 100%;
    min-height: 100%;
    background-color: #fff !important;
    padding: 20px 32px;
    padding-bottom:0;
    display: inline-flex;

    .table {
      margin-top: 24px !important;
    }

    .toolbar {
      background: #fff;
      width: 100%;
      padding: 16px 32px 26px 0;
    }
  }

  .control-box{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    &-input{
      width: 150px;
    }
  }
  .el-main {
    padding: 0;
  }

  .demo-table-expand {
    font-size: 0;
  }

  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }

  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
</style>
<style>
  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding: 10px 0;
    padding-right: 8px;
  }
  .el-tree-node__expand-icon.is-leaf{
    color: #c0c4cc;
  }
</style>
