import axios, {
  ResponseData
} from './base.ts'
import {
  AxiosPromise
} from 'axios'

const url = ''
export function getTable(data = {}) {
  return axios.request({
    url: `${url}/orders`,
    method: "get",
    params: data,
  })
}

export function getByID(id, data = {}) {
  return axios.request({
    url: `${url}/order/${id}`,
    method: "get",
    params: data,
  })
}

export function addData(data) {
  return axios.request({
    url: `${url}/order`,
    method: "post",
    data: data,
  })
}
export function editData(id, data) {
  return axios.request({
    url: `${url}/order/${id}`,
    method: "put",
    data: data,
  })
}
export function deleteData(id,data) {
  return axios.request({
    url: `${url}/order/${id}`,
    method: "delete",
    params:data,
  })
}


export function goAppoint(id, data) {
  return axios.request({
    url: `${url}/appoint/${id}`,
    method: "post",
    data: data,
  })
}
export function cancelData(id,data) {
  return axios.request({
    url: `${url}/order/cancel/${id}`,
    method: "put",
    data: data,
  })
}
//订单结束主动扣款
export function getPay(data = {}) {
  return axios.request({
    url: `${url}/tmp/order`,
    method: "get",
    params: data,
  })
}
export function getProfit(data = {}) {
  return axios.request({
    url: `${url}/order/profits`,
    method: "get",
    params: data,
  })
}
export function syncAli(id,data) {
  return axios.request({
    url: `${url}/order/ali/sync/${id}`,
    method: "get",
    params: data,
  })
}

export function getAli(id,data) {
  return axios.request({
    url: `${url}/order/ali/${id}`,
    method: "get",
    params: data,
  })
}

export function unfreezeAli(id,data) {
  return axios.request({
    url: `${url}/order/ali/unfreeze/${id}`,
    method: "post",
    data,
  })
}