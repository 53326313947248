<template>
    <!-- <section> -->
        <iframe class="nsq-box" src="https://redis-insight.zd-aichong.com"></iframe>
    <!-- </section> -->
  </template>
  
  <script>
  // import { getCascader } from "@/api/provider.js";
  
  let page_name = 'devopsRedis'
  export default {
    name: "devopsRedis",
  };
  </script>
  
  <style scoped lang="scss">
  .nsq-box{
    width: 98%;
    height: 98%;
    border:none;
  }
  </style>
  