<template>
  <section>
    <div class="banner">
      <!-- <tools-box v-if="admin_permission.indexOf('look')>-1 || admin_permission.indexOf('add')>-1"
        :config="toolsConfig"
        @openAdd="handleOpenAdd"
        @handleSearch='getTableData'
        @getVersionType="handleGetVersion"
        :showBack="false"
        :showAdd="admin_permission.indexOf('add')>-1"
      >
      </tools-box>
       -->
      <div class="control-box" style="margin-top: 10px;">
        <el-input class="control-box-input" v-model="whiteParams.white_keyword" style="margin-right: 10px;width:180px;" placeholder="请输入手机号" clearable></el-input>
        <el-button icon="el-icon-search" @click="getTableData" style="margin-right: 10px;">搜索</el-button>
        <el-button icon="el-icon-plus" type="primary" @click="handleOpenAdd" v-if="admin_permission.indexOf('add')>-1">添加白名单</el-button>
      </div>
    </div>
    <div class="container">
      <!-- 轮播列表 -->
      <div class="left">
        <el-table :data="whiteData" style="width: 100%;border:1px solid #eee;" class="table">
          <!-- 数据展示区 -->
          <el-table-column label="联系方式" prop="phone" align="left" width="120">
          </el-table-column>
          <el-table-column label="类型" prop="type" align="center" width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.type === 0"><el-tag type="success">特殊人群</el-tag></span>
              <span v-if="scope.row.type === 1"><el-tag type="danger">市场推广</el-tag></span>
              <span v-if="scope.row.type === 2"><el-tag type="warning">店主</el-tag></span>
              <span v-if="scope.row.type === 3"><el-tag type="info">店员</el-tag></span>
            </template>
          </el-table-column>
          <el-table-column label="投放点" prop="store_id" align="left" width="280">
            <template slot-scope="scope">
              <span v-if="scope.row.store_id === 0">-</span>
              <el-popover trigger="hover" placement="top" v-else>
                <p style="font-weight: bold;"> {{ scope.row.store.name }}</p>
                <p>{{scope.row.store.address }}</p>

                <div slot="reference" class="name-wrapper" style="width-space:nowrap;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">
                  <p>{{scope.row.store.name }}</p>
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="备注" prop="comment" align="left">
            <template slot-scope="scope">
              <el-popover trigger="hover" placement="top">
                <p> {{ scope.row.comment }}</p>
                <div slot="reference" class="name-wrapper" style="width-space:nowrap;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">
                  <span>{{scope.row.comment}}</span>
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" align="center" width="70px">
            <template slot-scope="scope">
              <div
                style="
                    width: 100%;
                    display: inline-flex;
                    justify-content: space-between;
                  "
              >
                <el-link style="color: #409eff;" @click="handleEdit(scope.row)" v-if="admin_permission.indexOf('update')>-1">
                  <el-tooltip class="item" effect="dark" content="修改" placement="top-start">
                    <i class="el-icon-edit" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>
                <el-link style="color: #409eff;" @click="handleDelete(scope.row.id)" v-if="admin_permission.indexOf('deleted')>-1">
                  <el-tooltip class="item" effect="dark" content="刪除" placement="top-start">
                  <i class="el-icon-delete" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>
              
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="width:100%;display:flex;justify-content: flex-end;box-sizing: border-box;padding: 10px;">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="whiteParams.page"
            :hide-on-single-page="false"
            :small="true"
            :page-size="whiteParams.page_count"
            layout="total, prev, pager, next,jumper"
            :total="total">
          </el-pagination>
        </div>
        <!-- 添加活动信息 -->
        <el-drawer
          title="添加白名单"
          :visible.sync="addDrawer"
          :direction="direction"
          :before-close="handleClose"
          
        >
          <add
            @close="handleClose"
            @success="handleDrawerSuccess"
            ref="newForm"
          ></add>
        </el-drawer>
        <!-- 修改活动信息 -->
        <el-drawer
          title="更改白名单"
          :visible.sync="editDrawer"
          :direction="direction"
          :before-close="handleClose"
        >
          <edit
            ref="newForm"
            v-if="editDrawer"
            @close="handleClose"
            @success="handleDrawerSuccess"
            :id="editParam.id"
          ></edit>
        </el-drawer>
      </div>
    </div>
  </section>
</template>

<script>
import {deleteList,getList} from '@/api/whiteList.js'
import ToolsBox from "@/components/tool/Index.vue";
import edit from "./components/edit.vue";
import add from "./components/add.vue";
let page_name = 'app_version'

export default {
  name: "App",
  components: { ToolsBox,edit,add },
  data() {
    return {
      admin_permission:[],
      height:0,
      toolsConfig: ["searchVersion"],
      storeData:[],
      whiteData:[],
      //搜索条件
      params: {
        page:1,
        page_count: 10
      },
      whiteParams: {
        page:1,
        page_count: 10
      },
      // 查询数据
      tableData: [
        
      ],
      total: 0,

      // 添加组件传参
      addDrawer: false,
      addParam:{

      },

      // 更新组件传参
      editDrawer: false,
      editParam: {
        id:0
      },

      direction: "rtl",
    };
  },
  mounted() {
    this.admin_permission = this.$store.state.permission.permissionArr[page_name]
    this.getTableData();
    if(document.body.clientHeight&&document.documentElement.clientHeight)
    {
      var clientHeight = (document.body.clientHeight<document.documentElement.clientHeight)?document.body.clientHeight:document.documentElement.clientHeight;
    }
    else
    {
      var clientHeight = (document.body.clientHeight>document.documentElement.clientHeight)?document.body.clientHeight:document.documentElement.clientHeight;
    }
    console.log(clientHeight)
    this.height = clientHeight-82-60-40-20-20-40
  },
  methods: {
    getTableData(){
      getList(this.whiteParams).then(res=>{
        if (res.code === 200){
          console.log(res.data)
          this.whiteData = res.data.white.data
          this.total = res.data.white.total
          this.whiteParams.page = res.data.white.current_page
        }
      })
    },
    handleCurrentChange(val){
      console.log(val)
    },
    handleGetVersion(e){
      this.params.type=e
    },
    // 删除
    handleDelete(id) {
      this.$confirm('删除后无法恢复，请慎重考虑，确认删除数据吗？')
        .then(_ => {
          deleteList(id).then(res=>{
              this.getTableData()
              this.$notify({
                title: '成功',
                message: '删除成功',
                type: 'success'
              });
            })
        })
        .catch(_ => {});
    },

    handleOpenAdd() {
      this.addDrawer = true;
    },
    handleEdit(row) {
      this.editParam = {
        id : row.id,
      }
      // console.log(row)
      // console.log(this.editParam)
      this.editDrawer = true
    },
    handleClose() {
      this.$confirm("确认关闭？")
        .then(() => {
          this.addDrawer = false;
          this.editDrawer = false;
          this.$refs.newForm.$refs.form.resetFields(); //清空子组件的内容
        })
        .catch(() => {});
    },
    handleDrawerSuccess(){
      this.addDrawer = false;
      this.editDrawer = false;
      this.getTableData();
      this.$refs.newForm.$refs.form.resetFields();//清空子组件的内容
    },
    prevpage() {
        this.whiteParams.page -= 1;
        this.getTableData();
    },
    nextpage() {
      this.whiteParams.page += 1;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.whiteParams.page = val;
      this.getTableData();
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/static/default";
.banner {
  width: 100%;
  background-color: white;
  padding: 10px 50px;
  // padding-left: 50px;
}
.container {
  background-color: #fff !important;
  padding: 20px 32px;
  display: flex;
  justify-content: flex-start;
  .left{
    width:100%;
  }
  .right{
    width:50%;
    margin-left:2%;
  }
  .table {
    margin-top: 24px !important;
  }
  .toolbar {
    background: #fff;
    width: calc(100% + 64px);
    padding: 16px 32px 26px 0;
    margin-left: -32px;
  }
}

.control-box{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    &-input{
      width: 150px;
    }
  }
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}

  .oneLineCls{
	  text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
     text-overflow: ellipsis;
     display: -webkit-box;
     -webkit-line-clamp: 1;
     line-clamp: 1;
     -webkit-box-orient: vertical;
  }
</style>
