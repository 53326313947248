<template>
  <section style="height: 100%">
    <div class="container">
      <!-- <el-aside style="padding-right: 20px;border-right:1px solid #e9eaf2;width: 150px;">
        <h1>审核状态</h1>
        <el-tree
          style="margin-top: 10px;margin-bottom:10px;"
          :data="status"
          node-key="id"
          icon-class="el-icon-s-flag"
          default-expand-all
          :expand-on-click-node="false"
          :highlight-current="true"
          :current-node-key="treeCurrentNode"
        >
          <span
            class="custom-tree-node"
            slot-scope="{ node, data }"
            @click="showTreeWorker(data.id)"
          >
            <span>
              {{ data.label }}
            </span>
          </span>
        </el-tree>
      </el-aside> -->
      <el-main style="width: 100%;box-sizing: border-box;padding-left:20px;">
        <div
          class="worker-tooltips"
          style="display:flex;flex-wrap:wrap; width: 100%;justify-content: flex-start"
        >
          <div class="search-box"><h1>投放点提现列表</h1></div>
          <div class="control-box" style="margin-top: 10px;">
            <div>
              <!-- <el-select v-model="params.store_id" placeholder="请选择投放点" filterable clearable style="margin-left:10px;margin-top:10px;" v-if="groupID===1">
                <el-option
                  v-for="item in storeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select> -->
              <el-input class="control-box-input" v-model="params.batch_id" placeholder="请输入微信批次单号" style="margin-left:10px;margin-top:10px;width:180px;"></el-input>
              <el-input class="control-box-input" v-model="params.out_batch_no" placeholder="请输入商家批次单号" style="margin-left:10px;margin-top:10px;width:180px;"></el-input>
              <el-input class="control-box-input" v-model="params.out_detail_no" placeholder="请输入商家明细单号" style="margin-left:10px;margin-top:10px;width:180px;"></el-input>
              <el-button icon="el-icon-search" @click="searchData" style="margin-left:10px;margin-top:10px;">搜索</el-button>
            </div>
          </div>
        </div>
        <el-table
          :data="table"
          style="width: 100%;"
          tooltip-effect="dark"
          empty-text="暂无数据"
          class="table"
        >
          <el-table-column label="投放点负责人" prop="store" align="center" width="120">
            <template slot-scope="scope">
              <el-popover trigger="hover" placement="top">
                <p v-for="(value,key) in scope.row.store_admin" :key="key">
                  投放点：{{value.name}}
                </p>
                <!-- <p>投放点: {{ scope.row.store_admin[0].phone }}</p> -->
                <div slot="reference" class="name-wrapper" style="width-space:nowrap;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">
                  <span>{{scope.row.store_admin[0].phone}}</span>
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="提现金额 " prop="amount" align="center" width="120">
          </el-table-column>
          <el-table-column label="审核状态" prop="status" align="center" width="150">
            <template slot-scope="scope">
              <span v-if="scope.row.status===0">审核中</span>
              <span v-if="scope.row.status===1">审核通过</span>
              <span v-if="scope.row.status===2">拒绝通过</span>
            </template>
          </el-table-column>
          <el-table-column label="微信批次单号" prop="batch_id" align="center" width="200"></el-table-column>
          <el-table-column label="商家批次单号" prop="out_batch_no" align="center" width="200"></el-table-column>
          <el-table-column label="商家明细单号" prop="out_detail_no" align="center"></el-table-column>
          <el-table-column label="提现申请时间" prop="created_at" align="center"></el-table-column>
          <el-table-column label="操作" width="90" align="center" fixed="right">
            <template slot-scope="scope">
              <div
                style="
                  width: 100%;
                  display: inline-flex;
                  justify-content:center;
                "
              >
                <el-link style="color: #409eff;margin-left:10px;margin-right:10px;" v-if="(scope.row.status ==0) && admin_permission.indexOf('update')>-1" @click="editTable(scope.row.id)">
                  <el-tooltip class="item" effect="dark" content="审核" placement="top-start">
                    <i class="el-icon-edit" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="width:100%;display:flex;justify-content: flex-end;box-sizing: border-box;padding: 10px;">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="params.page"
            :hide-on-single-page="false"
            :small="true"
            :page-size="params.page_count"
            layout="total, prev, pager, next,jumper"
            :total="total">
          </el-pagination>
        </div>
      </el-main>
    </div>
    <el-dialog
      title="提现审核"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <div style="text-align: center;font-size:20px;">是否通过审核？</div>
      <span slot="footer" style="display:flex;justify-content: space-between;width:50%;margin:0 auto;">
        <el-button @click="cancel()">拒绝</el-button>
        <el-button type="primary" @click="confirm()">同意</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
  import { getList,getListSelect, addData,editData,editPrice,deleteData,agentAppointment,agentRefuse,getCash,cashAgree,cashRefuse} from "@/api/store.js";
  import { getProvince,getCity,getArea} from "@/api/public.js";
  import { getGroups} from "@/api/system.js";

  import addComp from "./components/add.vue";
  import editComp from "./components/edit.vue";
  import storeList from "@/views/components/storeList.vue";
  import deviceShare from "@/views/components/deviceShare.vue";

  let page_name = 'store_cash'
  export default {
    name: "App",
    components:{addComp,editComp,storeList,deviceShare},
    data() {
      return {
        reason:'',
        admin_permission:[],
        dialogVisible:false,
        distributionVisible:false,
        devided:'',
        dialogTableVisible:false,
        dialogImgVisible:false,
        dialogRefuseVisible:false,
        refuseId:'',
        status: [
          {id:"",label:"全部"},
          {id:0,label:"审核中"},
          {id:1,label:"审核通过"},
          {id:2,label:"审核失败"},
        ],
        treeCurrentNode:"",
        unitList: [{
          value: 0,
          label: '每半小时'
        }, {
          value: 1,
          label: '每一小时'
        }],
        unit:'',
        unit_price:'',
        unitID:'',
        storeID:'',
        distributionID:'',
        provinceList:[],
        cityList:[],
        areaList:[],
        province_id:"",
        city_id:"",
        params: {
          page: 1,
          page_count: 10,
          batch_id:'',
          out_detail_no:'',
          out_batch_no:'',
          agent_id:'',
        },
        storeList:[],
        groupID:'',
        table:[],

        service: [],
        editID: '',
        total: 0,
        outerVisible: false,
        innerVisible: false,
        addDrawer: false,
        editDrawer: false,
        direction: "rtl",
        addTradeParams: {
          name: "",
          parent_id: 0
        },
        visible: false,
        scopeForm:{
          id:'',
          sort: ''
        }
      };
    },

    methods: {
      getTableData() {
        getCash(this.params).then(res=>{
          if(res.code === 200){
            this.table = res.data.data
            console.log(this.table)
            this.params.page = res.data.current_page
            this.params.page_count = res.data.per_page
            this.total = res.data.total
          }
        })
        getListSelect(this.params).then(res=>{
          if(res.code === 200){
            this.storeList = res.data
          }
        })
      },
      searchData(){
        this.params.page=1
        this.getTableData()
      },
      provinceClick(){
        let that = this
        this.provinceList.forEach(item => {
          if(item.id == that.params.province_id ){
            that.province_id = item.province_id
            console.log(that.province_id)
          }
        });
        getCity(that.province_id).then(res=>{
          if(res.code === 200){
            console.log(res.data)
            this.cityList = res.data
          }
        })
      },
      cityClick(){
        let that = this
        this.cityList.forEach(item => {
          if(item.id == that.params.city_id ){
            that.city_id = item.city_id
            console.log(that.city_id)
          }
        });
        getArea(that.city_id).then(res=>{
          if(res.code === 200){
            console.log(res.data)
            this.areaList = res.data
          }
        })
      },
      command(e,item){
          console.log(e)
          console.log(item)
        if(e === 'refuse'){
          this.refuseId = item.id
          this.dialogRefuseVisible =true
        }
        if(e === 'appointment'){
          agentAppointment(item.id).then(res=>{
            if(res.code===200){
              this.getTableData()
              this.$notify({
                title: '成功',
                message: '已通过',
                type: 'success'
              });
            }
          })
        }
        if(e === 'price'){
          this.dialogVisible=true
          this.unitID = item.id
          this.devided = item.devided
        }
        if(e === 'distribution'){
          this.distributionVisible=true
          this.distributionID = item.id
        }
        if(e === 'delete'){
          this.$confirm('删除后无法恢复，请慎重考虑，确认删除数据吗？')
          .then(_ => {
            deleteData(item.id).then(res=>{
              this.getTableData()
              this.$notify({
                title: '成功',
                message: '删除成功',
                type: 'success'
              });
            })
          })
          .catch(_ => {});
        }
      },
      
      refuse(){
        if(this.reason === ''){
          this.$message({
            message: "请输入拒绝理由",
            type: "success"
          });
          return false
        } 
        agentRefuse(this.refuseId,{reason:this.reason}).then(res=>{
          if(res.code === 200){
            this.dialogRefuseVisible = false
            this.getTableData()
            this.$notify({
              message: '已拒绝',
            });
          }
        })
      },
      cancel(){
        let _this = this
        this.dialogVisible=false
        cashRefuse(this.editID).then((res) => {
          if (res.code === 200) {
            _this.$message({
              message: "已拒绝",
              type: "success"
            });
            _this.getTableData()
          }
        });
      },
      confirm(){
        let _this = this
        this.dialogVisible=false
        cashAgree(this.editID).then((res) => {
          if (res.code === 200) {
            _this.$message({
              message: "已通过审核",
              type: "success"
            });
            _this.getTableData()
          }
        });
      },
      writeScope(id){
        console.log(id)
        this.scopeForm.id = id
        let tmp = [];
        this.service.forEach((value,key)=>{
          tmp[key] = value
          if (id === value.id){
            this.scopeForm.sort = parseInt(value.sort)
            tmp[key].write_scope = true
          }
        })
        this.service = tmp;
      },

      scopeChange(){
        editData(this.scopeForm.id,{sort:this.scopeForm.sort}).then(res=>{
          if (res.code === 200){
            this.searchSet()
          }
        })
      },

      imgClick(e){
        this.img = e
        this.dialogImgVisible =true
      },
      imgClick2(e){
        this.img = e
        this.dialogImgVisible =true
      },
      //选择TREE
      showTreeWorker(id) {
        console.log(id)
          this.params.status = id;
          this.getTableData();
      },

      handleDetail(e){
        console.log(e)
        this.storeID=e
        this.dialogTableVisible = true
      },
      // 打开添加
      handleOpenAdd() {
        this.addDrawer = true;
      },
      // 关闭添加/修改
      handleClose() {
        this.$confirm("确认关闭？")
          .then((_) => {
            this.addDrawer = false;
            this.editDrawer = false;
            this.dialogVisible = false
            this.dialogDeductionVisible = false
          })
          .catch((_) => {});
      },

      editTable(id){
        this.editID = id
        this.dialogVisible = true
      },
      // 添加/修改成功
      handleDrawerSuccess() {
        console.log(1)
        this.getTableData()
        this.addDrawer = false;
        this.editDrawer = false;
        this.distributionVisible = false
        // this.searchSet()
        this.$refs.newForm.$refs.form.resetFields();
      },
      // 清空子组件
      clearContent() {
        this.$refs.newForm.$refs.form.resetFields();
      },
      // 分页
      prevpage() {
        this.params.page -= 1;
        this.getTableData();
      },
      nextpage() {
        this.params.page += 1;
        this.getTableData();
      },
      handleCurrentChange(val) {
        this.params.page = val;
        this.getTableData();
      },
    },
    mounted() {
      this.admin_permission = this.$store.state.permission.permissionArr[page_name]
      this.getTableData();
      
      this.groupID = this.$store.state.user.userData.group_id
      getGroups().then(res=>{
        if(res.code === 200){
          this.groupList = res.data
        }
      })
      getProvince().then(res=>{
        if(res.code === 200){
          this.provinceList = res.data
        }
      })
    }
  };
</script>

<style scoped lang="scss">
  @import "@/static/default";

  .container {
    width: 100%;
    min-height: 100%;
    background-color: #fff !important;
    padding: 20px 32px;
    padding-bottom:0;
    display: inline-flex;

    .table {
      margin-top: 24px !important;
    }

    .toolbar {
      background: #fff;
      width: 100%;
      padding: 16px 32px 26px 0;
    }
  }

  .control-box{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    &-input{
      width: 150px;
    }
  }
  .el-main {
    padding: 0;
  }

  .demo-table-expand {
    font-size: 0;
  }

  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }

  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
</style>
<style>
  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding: 10px 0;
    padding-right: 8px;
  }
  .el-tree-node__expand-icon.is-leaf{
    color: #c0c4cc;
  }
</style>
