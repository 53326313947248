<template>
  <section style="height: 100%;">
    <div class="banner">
      <tools-box v-if="admin_permission.indexOf('look')>-1 || admin_permission.indexOf('add')>-1"
        :config="toolsConfig"
        @openAdd="addChild"
        :showBack="false"
        :showAdd="admin_permission.indexOf('add')>-1"
      >
      </tools-box>
    </div>
    <div class="container">
      <!-- 轮播列表 -->
      <el-table v-if="height>0" :data="tableData" style="width: 100%" class="table" :height="height">
        <!-- 数据展示区 -->
        <el-table-column label="排序" prop="sort" align="left" width="60px">
        </el-table-column>
        <el-table-column label="名称" prop="name" align="left" width="180px">
          <template slot-scope="scope">
            <span v-if="scope.row.parent_id>0">　　|-{{scope.row.name}}</span>
            <span v-else>{{scope.row.name}}</span>
          </template>
        </el-table-column>
        <el-table-column label="标识" prop="menu_id" align="left" width="240px">
        </el-table-column>
        <el-table-column label="URL" prop="url" align="left" width="200px">
        </el-table-column>
        <el-table-column label="是否启用" prop="enable" align="left">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.enable_bool"
              active-text="已启用"
              inactive-text="未启用"
              @change="changeEnable(scope.row.enable_bool,scope.row)"  
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" align="right" width="150px">
          <template slot-scope="scope">
            <div
              style="
                  width: 100%;
                  display: inline-flex;
                  justify-content: flex-end;
                "
            >
              <el-link style="color: #409eff" @click="addChild(scope.row)" v-if="scope.row.parent_id === 0"><i class="el-icon-sort-down"></i>子菜单</el-link>
              
              <el-link style="color: #409eff;margin-left:20px;" @click="editChild(scope.row)">
                <el-tooltip class="item" effect="dark" content="修改" placement="top-start">
                  <i class="el-icon-edit" style="font-size:16px;"></i>
                </el-tooltip>
              </el-link>
              <el-link style="color: #409eff;margin-left:20px;" @click="handleDelete(scope.row.id)" v-if="scope.row.menu_id !== 'system_management'">
                <el-tooltip class="item" effect="dark" content="刪除" placement="top-start">
                <i class="el-icon-delete" style="font-size:16px;"></i>
                </el-tooltip>
              </el-link>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <!-- 添加活动信息 -->
      <el-drawer
        title="添加菜单"
        :visible.sync="addDrawer"
        :direction="direction"
        :before-close="handleClose"
        
      >
        <add
          @close="handleClose"
          @success="handleDrawerSuccess"
          ref="newForm"
          :parent_id="addSonMenu.parent_id"
          :parent_name="addSonMenu.parent_name"
        ></add>
      </el-drawer>

      <!-- 修改活动信息 -->
      <el-drawer
        title="更改菜单"
        :visible.sync="editDrawer"
        :direction="direction"
        :before-close="handleClose"
      >
        <edit
          ref="newForm"
          v-if="editDrawer"
          @close="handleClose"
          @success="handleDrawerSuccess"
          :id="editMenu.id"
          :changeInfo="changeInfo"
        ></edit>
      </el-drawer>
    </div>
  </section>
</template>

<script>
import {getMenus,deleteMenu,updateMenu} from '@/api/system'
import ToolsBox from "@/components/tool/Index.vue";
import edit from "./components/edit.vue";
import add from "./components/add.vue";

let page_name = 'system_menu'
export default {
  name: "App",
  components: { ToolsBox,edit,add },
  data() {
    return {
      admin_permission:[],
      height:0,
      toolsConfig: [],
      params: {

      },
      tableData: [
        
      ],

      addSonMenu:{
        parent_id:0,
        parent_name:''
      },
      editMenu: {
        id:0
      },

      show: false,
      total: 0,
      id: 1,
      addDrawer: false,
      editDrawer: false,
      direction: "rtl",
      allInfo: [],
      drawer: false,
      options3: [],
      close: false,
      changeInfo: "",
    };
  },
  mounted() {
    this.admin_permission = this.$store.state.permission.permissionArr[page_name]
    this.getTableData();
    if(document.body.clientHeight&&document.documentElement.clientHeight)
    {
      var clientHeight = (document.body.clientHeight<document.documentElement.clientHeight)?document.body.clientHeight:document.documentElement.clientHeight;
    }
    else
    {
      var clientHeight = (document.body.clientHeight>document.documentElement.clientHeight)?document.body.clientHeight:document.documentElement.clientHeight;
    }
    this.height = clientHeight-82-60-40-20-14
  },
  methods: {
    getTableData(){
      getMenus(this.params).then(res=>{
        if (res.code === 200){
          let tmp = []
          res.data.forEach((value,key) => {
            tmp[key] = value
            tmp[key]['enable_bool'] = value.enable ? true : false
          })
          this.tableData = tmp
        }
      })
    },
    changeEnable(status,row){
      console.log(status, row)
      this.$confirm('确认修改数据吗？')
          .then(_ => {
              updateMenu(row.id,{"enable": status ? 1 : 0}).then(res=>{
                this.getTableData()
                if(res.code===200){
                  this.$notify({
                  title: '成功',
                  message: '修改成功',
                  type: 'success'
                });
                }
              })
          })
          .catch(_ => {});
    },
    
    //   handleBack(){
    //     this.getActiveInfo();
    //     this.showBack=false;
    //     this.params={}
    //   },

      // 删除轮播
      handleDelete(id) {
        this.$confirm('删除后无法恢复，请慎重考虑，确认删除数据吗？')
          .then(_ => {
              deleteMenu(id).then(res=>{
                this.getTableData()
                this.$notify({
                  title: '成功',
                  message: '删除成功',
                  type: 'success'
                });
              })
          })
          .catch(_ => {});
      },

    //   // 修改信息
    handleEdit(index, row) {
      this.id = row.id;
      this.editDrawer = true;
      this.changeInfo = row;
    },

    handleOpenAdd() {
      this.addDrawer = true;
    },
    addChild(value='') {
      if(value){
        this.addSonMenu = {
          parent_id : value.id,
          parent_name: value.menu_id
        }
      }
     
      this.addDrawer = true
    },
    editChild(value){
      this.editMenu = {
        id : value.id,
      }
      this.editDrawer = true
    },

    //   // 关闭
    handleClose() {
      this.$confirm("确认关闭？")
        .then(() => {
          this.addDrawer = false;
          this.editDrawer = false;
          this.$refs.newForm.$refs.form.resetFields(); //清空子组件的内容
        })
        .catch(() => {});
    },

    // 添加/修改成功
    handleDrawerSuccess(){
      this.addDrawer = false;
      this.editDrawer = false;
      this.getTableData();
      this.$refs.newForm.$refs.form.resetFields();//清空子组件的内容
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/static/default";
.banner {
  width: 100%;
  background-color: white;
  padding: 10px 50px;
  // padding-bottom: 0px;
}
.container {
  background-color: #fff !important;
  padding: 0 32px;
  .table {
    margin-top: 24px !important;
  }
  .toolbar {
    background: #fff;
    width: calc(100% + 64px);
    padding: 16px 32px 26px 0;
    margin-left: -32px;
  }
}

.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}


</style>
