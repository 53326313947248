import axios, {ResponseData} from './base.ts'
import {AxiosPromise} from 'axios'
const path=''

export function getList(data = {}) {
  return axios.request({
    url: `/whitelists`,
    method: "get",
    params: data,
  })
}
export function getByID(id, params={}){
  return axios.request({
    url:`/whitelist/${id}`,
    method: "get",
    params,
  })
}
export function deleteList(id,data) {
  return axios.request({
    url: `/whitelist/${id}`,
    method: "delete",
    params:data,
  })
}
export function addData(data) {
  return axios.request({
    url: `/whitelist`,
    method: "post",
    data: data,
  })
}
export function updateList(id, data) {
  return axios.request({
    url: `/whitelist/${id}`,
    method: "put",
    data: data,
  })
}
export function getSettingList(data = {}) {
  return axios.request({
    url: `/whitelist/setting`,
    method: "get",
    params: data,
  })
}
export function updateSettingList(data) {
  return axios.request({
    url: `/whitelist/setting`,
    method: "put",
    data: data,
  })
}