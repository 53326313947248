<template>
  <section style="height: 100%">
    <div class="container">
      <el-main style="width: 100%;box-sizing: border-box;padding-left:20px;">
        <div
          class="worker-tooltips"
          style="display:flex;flex-wrap:wrap; width: 100%;justify-content: flex-start"
        >
          <div class="search-box"><h1>设备类型列表</h1></div>
          <div class="control-box" style="margin-top: 10px;">
            <el-input class="control-box-input" v-model="params.name" placeholder="请输入搜索名字"></el-input>
            <el-button icon="el-icon-search" @click="getTableData" style="margin-left: 10px;">搜索</el-button>
            <el-button icon="el-icon-plus" type="primary" @click="handleOpenAdd" v-if="admin_permission.indexOf('add')>-1">添加设备类型</el-button>
          </div>
        </div>
        <el-table
          :data="table"
          style="width: 100%;"
          tooltip-effect="dark"
          empty-text="暂无数据"
          class="table"
        >
          <el-table-column label="id" prop="id" align="left" width="160"></el-table-column>
          <el-table-column label="名称" prop="name" align="left"></el-table-column>
          <el-table-column label="备注" prop="comment" align="center"></el-table-column>

          <el-table-column label="操作" fixed="right" align="center" width="70px">
            <template slot-scope="scope">
              <div
                style="
                  width: 100%;
                  display: inline-flex;
                  justify-content: center;
                "
              >

                <el-link style="color: #409eff;margin-left:10px;margin-right:10px;" v-if="admin_permission.indexOf('update')>-1" @click="handleEdit(scope.row.id)">
                  <el-tooltip class="item" effect="dark" content="修改" placement="top-start">
                    <i class="el-icon-edit" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>
                <el-link style="color: #409eff;" @click="handleDelete(scope.row.id)" v-if="admin_permission.indexOf('deleted')>-1">
                  <el-tooltip class="item" effect="dark" content="刪除" placement="top-start">
                  <i class="el-icon-delete" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="width:100%;display:flex;justify-content: flex-end;box-sizing: border-box;padding: 10px;">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="params.page"
            :hide-on-single-page="false"
            :small="true"
            :page-size="params.page_count"
            layout="total, prev, pager, next,jumper"
            :total="total">
          </el-pagination>
        </div>
      </el-main>
    </div>

    <el-drawer
      title="更改设备类型"
      :visible.sync="editDrawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <edit-comp v-if="editDrawer" @close="handleClose" @success="handleDrawerSuccess" :id="editID" ref="newForm"></edit-comp>
    </el-drawer>

    <!-- 添加规则 -->
    <el-drawer
      title="添加设备类型"
      :visible.sync="addDrawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <add-comp
        @close="handleClose"
        @success="handleDrawerSuccess"
        @clear="clearContent"
        ref="newForm"
        v-if="addDrawer"
      ></add-comp>
    </el-drawer>
  </section>
</template>

<script>
  import { searchTypeList,deleteType} from "@/api/device.js";

  import addComp from "./components/add.vue";
  import editComp from "./components/edit.vue";

  let page_name = 'device_type'
  export default {
    name: "App",
    components:{addComp,editComp},
    data() {
      return {
        admin_permission:[],
        status: [
          {id:"",label:"全部"},
          {id:0,label:"审核中"},
          {id:1,label:"审核通过"},
          {id:2,label:"审核失败"},
        ],
        treeCurrentNode:"",

        params: {
          page: 1,
          page_count: 10
        },

        table:[],

        service: [],
        editID: '',
        groupID:"",
        total: 0,
        addDrawer: false,
        editDrawer: false,
        direction: "rtl",
        visible: false,
        scopeForm:{
          id:'',
          sort: ''
        }
      };
    },

    methods: {
      getTableData() {
        searchTypeList(this.params).then(res=>{
          if(res.code === 200){
            console.log(res.data.data)
            this.table = res.data.data
            // this.table = Object.values(res.data.data)
            console.log(this.table)
            this.params.page = res.data.current_page
            this.params.page_count = res.data.per_page
            this.total = res.data.total
          }
        })
      },

      writeScope(id){
        this.scopeForm.id = id
        let tmp = [];
        this.service.forEach((value,key)=>{
          tmp[key] = value
          if (id === value.id){
            this.scopeForm.sort = parseInt(value.sort)
            tmp[key].write_scope = true
          }
        })
        this.service = tmp;
      },

      scopeChange(){
        editData(this.scopeForm.id,{sort:this.scopeForm.sort}).then(res=>{
          if (res.code === 200){
            this.searchSet()
          }
        })
      },

      //选择TREE
      showTreeWorker(id, pid) {
          this.params.status = id;
          this.getTableData();
      },

      // 打开添加
      handleOpenAdd() {
        this.addDrawer = true;
      },
      // 打开修改
      handleEdit(id) {
        this.editID = id
        console.log(this.editID)
        this.editDrawer = true;
      },
      handleDelete(id) {
        this.$confirm('删除后无法恢复，请慎重考虑，确认删除数据吗？')
          .then(_ => {
            deleteType(id).then(res=>{
              this.getTableData()
              this.$notify({
                title: '成功',
                message: '删除成功',
                type: 'success'
              });
            })
          })
          .catch(_ => {});
      },
      // 关闭添加/修改
      handleClose() {
          this.addDrawer = false;
          this.editDrawer = false;
      },
      // 添加/修改成功
      handleDrawerSuccess() {
        this.handleClose();
        this.getTableData()
        this.$refs.newForm.$refs.form.resetFields();
      },
      // 清空子组件
      clearContent() {
        this.$refs.newForm.$refs.form.resetFields();
      },
      // 分页
      prevpage() {
        this.params.page -= 1;
        this.getTableData();
      },
      nextpage() {
        this.params.page += 1;
        this.getTableData();
      },
      handleCurrentChange(val) {
        this.params.page = val;
        this.getTableData();
      },
    },
    mounted() {
      this.admin_permission = this.$store.state.permission.permissionArr[page_name]
      console.log(this.admin_permission)
      this.groupID = this.$store.state.user.userData.group_id
      this.getTableData();
    }
  };
</script>

<style scoped lang="scss">
  @import "@/static/default";

  .container {
    width: 100%;
    min-height: 100%;
    background-color: #fff !important;
    padding: 20px 32px;
    padding-bottom:0;
    display: inline-flex;

    .table {
      margin-top: 24px !important;
    }

    .toolbar {
      background: #fff;
      width: 100%;
      padding: 16px 32px 26px 0;
    }
  }

  .control-box{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    &-input{
      width: 150px;
    }
  }
  .el-main {
    padding: 0;
  }

  .demo-table-expand {
    font-size: 0;
  }

  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }

  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
</style>
<style>
  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding: 10px 0;
    padding-right: 8px;
  }
  .el-tree-node__expand-icon.is-leaf{
    color: #c0c4cc;
  }
</style>
