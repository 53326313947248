<template>
    <section>
      <div class="container">
        <div style="margin-left:10%;margin-right:10%;margin-top:30px;">
            <el-form ref="form" :model="form" label-width="85px">
              <el-row :gutter="40">
                <el-col v-for="(value,key) in form.settings" :span="12">
                  <el-divider content-position="center">{{form.titles[key]}}规则</el-divider>
                  <div v-if="key === 3 || key === 2 " style="text-align: center;font-size: 14px;margin-top:-10px;margin-bottom:20px;" >该规则只能在该店铺中适用</div>
                  <el-form-item label="免付费时间">
                      <el-input v-model="value.time_limit"><template slot="append">小时</template></el-input>
                      <div>免付费时间内归还，无需付款</div>
                  </el-form-item>
                  <el-form-item label="价格优惠">
                      <el-input v-model="value.preferential_rules"></el-input>
                      <div>免付费时间后，折扣规则（0.98 = 98折）</div>
                  </el-form-item>
                  <el-form-item label="免付费次数">
                      <el-input v-model="value.times"><template slot="append">次</template></el-input>
                      <div>免付费服务次数，0为不启用免付费，100为最大值</div>
                  </el-form-item>
                </el-col>
              </el-row>
              <div style="width: 100%;display:inline-flex; justify-content: flex-end;padding: 0 40px; box-sizing: border-box;">
                <el-button>重置</el-button>
                <el-button type="primary" @click="onSubmit">立即修改</el-button>
              </div>
            </el-form>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import {getSettingList,updateSettingList} from '@/api/whiteList.js'
  import ToolsBox from "@/components/tool/Index.vue";
  import edit from "./components/edit.vue";
  import add from "./components/add.vue";
  let page_name = 'app_version'
  
  export default {
    name: "App",
    components: { ToolsBox,edit,add },
    data() {
      return {
        admin_permission:[],
        height:0,
        form:[],
        settings:[]
      };
    },
    mounted() {
      this.admin_permission = this.$store.state.permission.permissionArr[page_name]
      this.getTableData();
      if(document.body.clientHeight&&document.documentElement.clientHeight)
      {
        var clientHeight = (document.body.clientHeight<document.documentElement.clientHeight)?document.body.clientHeight:document.documentElement.clientHeight;
      }
      else
      {
        var clientHeight = (document.body.clientHeight>document.documentElement.clientHeight)?document.body.clientHeight:document.documentElement.clientHeight;
      }
      console.log(clientHeight)
      this.height = clientHeight-82-60-40-20-20-40
    },
    methods: {
      getTableData(){
        getSettingList(this.params).then(res=>{
          if (res.code === 200){
            console.log(res.data)
            this.form = res.data
            this.settings = res.data.settings
          }
        })
      },
      // 删除
      onSubmit() {
        this.$confirm('确认修改吗？')
          .then(_ => {
            let form = {
              'settings':JSON.stringify(this.form.settings),
            }
            updateSettingList(form).then(res=>{
              if(res.code === 200){
                this.getTableData()
                this.$notify({
                  title: '成功',
                  message: '修改成功',
                  type: 'success'
                });
              }
            })
          })
          .catch(_ => {});
      },
    },
  };
  </script>
  
  <style scoped lang="scss">
  @import "@/static/default";
  .banner {
    width: 100%;
    background-color: white;
    padding: 10px 50px;
    // padding-left: 50px;
  }
  .container {
    background-color: #fff !important;
    padding: 20px 32px;
    display: flex;
    justify-content: flex-start;
  }
  
  </style>
  